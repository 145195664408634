export const SelectField = ({
    id, name, value,
    leading, trailing,
    color, onChange, inputStyle, ...props
}) => {
    let attr={name, onChange, value, style:inputStyle}
    if(id) attr.id = id;
    let wrapperClass = "select"
    if(color) wrapperClass = `${wrapperClass} select--${color}`
    return <div className={wrapperClass}>
        {leading && <div className="select__leading">{leading}</div>}
        <select {...attr}>
            {props.children}
        </select>
        {trailing && <div className="select__trailing">{trailing}</div>}
    </div>
}

export const SelectOption = ({value, ...props}) => {
    const attr={value}
    return <option className="select__option" {...attr}>{props.children}</option>
}

const Select = {
    Field: SelectField,
    Option: SelectOption,
}

export default Select