import List from './EntityList'
import New from './NewEntity'
import RegisterSchool from './RegisterSchool'
import SchoolView from './School'
import StateView from './State'

const exportObj = {
    List,
    New,
    RegisterSchool,
    SchoolView,
    StateView
}

export default exportObj