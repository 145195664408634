import { useState } from 'react'
import { render } from 'react-dom'
import { useEffect } from 'react'
import Components from '../../components'
import Client from '../../services'


const headerMap = {
    "index": {label: "Index",},
    "_id": {label: "Id",},
    "_cid": {label: "Course Id",},
    "level": {label: "Level",},
    "term": {label: "Term",},
    "amount": {label: "Amount",},
    "dueDate": {label: "Due on",},
    "done": {label: "Payment Done",},
    "_pid": {label: "Payment Id",},
    "options": {label: "options"},
}


 


const FeesCollection = ({history}) => {
    const [list, setList] = useState([])
    const [sid, setSid ] = useState("")
    const [searching, setSearching ] = useState(false)
    const [showResult, setShowResult ] = useState(false)
    const [enrollment, setEnrollment ] = useState({_uid: {name: ""}, _cid: {_id: "", title: "", currentLevel: 0}})

    let timeoutId = null

    const handleSearchStudent = async(e, callback) => {
        const {value} = e.target
        if(showResult === false) setShowResult(true)
        clearTimeout(timeoutId)
        setSid(value)
        if(value.length < 3) callback([])
        timeoutId = setTimeout(async()=>{
            setSearching(true)
            const {success, data} = await Client.api.get('/accountant/user/student/search?qname=' + value)
            if(success){
                callback(data)
            }else{
                callback([])
            }
            setSearching(false)
        }, 500)
    }
    
    const handleGetList = async (sid, enrollmentId) => {
        setShowResult(false)
        clearTimeout(timeoutId)

        // const {success, data} = await Client.api.get('/accountant/course/fees?username=' + sid)
        const {success, data} = await Client.api.get('/accountant/course/fees/enrollment?id=' + enrollmentId)
        if(success){
            const list = data.list.map((fee, i)=>{
                const dueDate = (new Date(fee.dueDate))
                return {
                    index: i,
                    ...fee,
                    done: fee.done ? "Done" : "-",
                    dueDate: `${dueDate.getDate()}/${dueDate.getMonth()+1}/${dueDate.getFullYear()}`,
                    dueDate: dueDate.toDateString(),
                    options: fee.done? 
                        <button onClick={(e)=>handleMakePayment(fee, e)} >View details</button> 
                        : <button onClick={(e)=>handleMakePayment(fee, e)} >Make payment</button>
                }
            })
            setEnrollment(data.enrollment)
            setList(list)
        }else{
            setList([])
        }
    }

    const handleMakePayment = (fee, e) => {
        e.preventDefault()
        history.push(`/accountant/pay/course/fee/${fee._id}`)
    } 


    return <div className="accountant fees-collection">
        <center><h2>Fees Payment</h2></center>
        <div className="u-flex--row u-flex--center">
            <Components.input.Search
                placeholder="Search student name or id"
                onValueChange={handleSearchStudent}
                show={showResult}
                render={(enroll) => {
                    return <div 
                        className="fees-collection__search-result-item"
                        onClick={()=>{
                            setSid(enroll._uid.username)
                            handleGetList(enroll._uid.username, enroll._id)
                        }}
                    > 
                        <div>{enroll._uid.name}</div>
                        <div>{enroll._uid.username}</div>
                    </div>
                }}
            />
        </div>
        <div className="u-flex--row">
                <div className="u-flex--1">
                    <h3>Student Details</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Student Id</td>
                                <td>{enrollment._uid.username}</td>
                            </tr>
                            <tr>
                                <td>Student Name</td>
                                <td>{enrollment._uid.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="u-flex--1">
                    <h3>Enrollment Details</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Enrollment Id</td>
                                <td>{enrollment._id}</td>
                            </tr>
                            <tr>
                                <td>Course Id</td>
                                <td>{enrollment._cid._id}</td>
                            </tr>
                            
                            <tr>
                                <td>Course Name</td>
                                <td>{enrollment._cid.title}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </div>
        
        <div>
            <h3>Course Fees</h3>
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "_id", "_cid", "level", "term", "amount", "dueDate", "done", "_pid", "options"]}
                data={list}
            />
        </div>
        
    </div>
}

export default FeesCollection