import React, { useEffect, useState } from 'react'
import Components from '../../../../components'
import Client from '../../../../services'

class SchoolView extends React.Component{
    
    state = {
        data: {data:{}}
    }
    // ===============================================================================================
    // Lifecycle
    // ===============================================================================================
    componentDidMount(){
        this.loadData()
    }
    // ===============================================================================================
    // Api Calls
    // ===============================================================================================
    loadData = async() => {
        const {id} = this.props.match.params
        
        const {success, data} = await Client.api.get(`/state-coordinator/entity/school/${id}`)
        if(success){
            this.setState({data})
        }else{
            
        }
    }
    changeRoyalty = async(royalty) => {
        const {id} = this.props.match.params
        const {batch} = this.state
        const payload = {
            royalty: Number(royalty)
        }
        const {success, message} = await Client.api.put(`/state-coordinator/entity/school/${id}`, payload)
        if (success){
            await Client.alert.fire({
                icon: "success",
                title: "Success",
                body: message || "Royalty changed succesfully.",
                confirmButton: true,
            })
            this.loadData()
        }else{
            Client.alert.fire({
                icon: "failed",
                title: "Failed",
                body: message || "failed to chnage royalty percentage.",
                confirmButton: true
            })
        }
    }
    // ===============================================================================================
    // UI Handlers
    // ===============================================================================================
    handleChangeRoyalty = async(e) => {
        e.preventDefault()
        let royalty = ""
        const C = () => {
            const [value, setValue] = useState("")
            const [user, setUser] = useState({})
            useEffect(()=>{
                royalty = value
            }, [value])
            return <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Royalty</td>
                            <td>
                                <Components.input.TextInput onChange={(e) => setValue(e.target.value)} value={value} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        Client.alert.fire({
            title: "Update Royalty (%)",
            body: <C />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                this.changeRoyalty(royalty)
            }
        })
    }

    // ===============================================================================================
    // Rendering methods
    // ===============================================================================================

    
    // ===============================================================================================
    // Final Rendering
    // ===============================================================================================

    render(){
        const {data} = this.state
        return <div className="school-view">
            <h3>School Details</h3>

            <table>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{data.name}</td>
                    </tr>
                    <tr>
                        <td>Entity Code</td>
                        <td>{data.entity}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{data.address}</td>
                    </tr>
                    <tr>
                        <td>Royalty</td>
                        <td>{data.data.royalty * 100}</td>
                        <td><Components.buttons.FlatButton onClick={this.handleChangeRoyalty}>Change</Components.buttons.FlatButton></td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

export default SchoolView