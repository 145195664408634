
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Components from '../../../components';
import Client from '../../../services';


const headerMap = {
    "index": {label: "Index",},
    "entity": {label: "Id",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "kind": {label: "Kind"},
    "_coordinator": {label: "Co-ordinator Id"},
    "options": {label: "Options"}
}


class EntityList extends React.Component{
    state = {
        list: []
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = async () => {
        const {data} = await Client.api.get('/admin/entity/list')
        this.setState({list: data})
    }

    handleChangeCoOrdinator = (entity, e) => {
        e.preventDefault()
        let coordinatorId = ""
        const C = () => {
            const [value, setValue] = useState("")
            useEffect(()=>{
                coordinatorId = value
            }, [value])
            return <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Old Coordinator</td>
                            <td>{entity._coordinator}</td>
                        </tr>
                        <tr>
                            <td>New Coordinator Id</td>
                            <td>
                                <Components.input.TextInput onChange={(e) => setValue(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        Client.alert.fire({
            title: "Change co-ordinator",
            body: <C />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const payload = {
                    schoolId: entity._id, 
                    coordinatorId: coordinatorId
                }
                const {success, message} = await Client.api.put('/admin/entity/change/school/coordinator', payload)
                if (success){
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Co-ordintor changed succesfully.",
                        confirmButton: true,
                    })
                    this.props.history.replace('/admin/entity/list')
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "Failed to change the co-ordinator.",
                        confirmButton: true
                    })
                }
            }
        })
    }

    handleOpenDetails = (v) => {
        const urls = {
            [Client.constants.entity.kind.ENTITY_STATE]: `/admin/entity/state/${v._id}`,
            [Client.constants.entity.kind.ENTITY_SCHOOL]: `/admin/entity/school/${v._id}`,
        }
        const url = urls[v.kind]
        
        this.props.history.push(url)
    }


    renderList = () => {
        return this.state.list.map((v,index) => {
            // return {index, ...v, options: <button onClick={this.handleChangeCoOrdinator.bind(this, v)}>Change Co Ordinator</button> }
            return {index, ...v, options: <button onClick={this.handleOpenDetails.bind(this, v)}>View</button> }
        })
    }
    render(){
        return <div className="new-coordinator">
            <ul>
                <li><Link to="/admin/entity/new">Create new entity</Link></li>
                {/* <li><Link to="/admin/entity/school/register">Register New School</Link></li> */}
            </ul>
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "entity", "kind", "name", "address", "options"]}
                data={this.renderList()}
            />
        </div>
    }
}

export default EntityList