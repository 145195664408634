import React, { useEffect } from 'react'
import { useState } from 'react'
import Components from '../../../components'
import Client from '../../../services'
import StudentList from './StudentList'
import TeacherList from './TeacherList'

class StudentBatchDetails extends React.Component{
    state = {
        batch: {},
        students: [],
        teachers: [],
    }
    async componentDidMount(){
        const {id} = this.props.match.params
        Client.api.get(`/teacher/course/batch/${id}`).then( ({success, data:batch, message}) => {
            if(success){
                this.setState({
                    batch
                })
            }else{
                Client.alert.fire({
                    icon: "failure",
                    title: "Loading failed",
                    body: message,
                    confirmButton: true,
                })
            }
        })
        Client.api.get(`/teacher/course/batch/${id}/students`).then( ({success, data:students}) => {
            if(success){
                this.setState({
                    students
                })
            }
        })
        Client.api.get(`/teacher/course/batch/${id}/teachers`).then( ({success, data:teachers}) => {
            if(success){
                this.setState({
                    teachers
                })
            }
        })
    }
 

    render(){
        const {batch} = this.state
        return <div className="student-batch-details">
            <h2>Course Batch Details</h2>
            <div>Batch Title: {batch.title}</div>
            <div>Batch Description: {batch.description}</div>
            <div>No of Students: {batch.nStudents}</div>
            <div>No of Teachers: {batch.nTeachers}</div>

            <div>
                <h3>Schedules</h3>
                {/* <StudentList list={this.state.students} /> */}
            </div>

            <div>
                <h3>Students</h3>
                <StudentList list={this.state.students} />
            </div>
            <div>
                <h3>Teachers</h3>
                <TeacherList list={this.state.teachers} />
            </div>

        </div>
    }
}

export default StudentBatchDetails