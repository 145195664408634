import Accountant from './Accountant'
import CourseForm from './CourseForm'
import Course from './Course';
import CourseBatch from './CourseBatch';
import Dashboard from './Dashboard'
import Schedule from './Schedule';
import Student from './Student';
import Teacher from './Teacher';

const exportObj = {
    Accountant,
    CourseForm,
    Course,
    CourseBatch,
    Dashboard,
    Schedule,
    Student,
    Teacher
}

export default exportObj