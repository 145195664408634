import './styles/index.scss'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx'
import {BrowserRouter as Router} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import Client from './services';
import Navigation from './pageComponents/Navigation';
import Components from './components';
import Containers from './containers';
import UnauthorizedApp from './unauthorized';


const run = async () => {
  await Client.session.initialize()
  
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Navigation 
          publicApp = { <div>Public App</div> }
          publicApp = { <UnauthorizedApp.Dashboard/> }
          authorizedApp={<App />}
        />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

run()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
