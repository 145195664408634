import React from 'react';
import Components from '../../../components';
import Client from '../../../services';
import OnlineCourseSelector from './OnlineCourseSelector';


const onlineCourseTablesLabels = {
    "index": {label: "Sl.No.",},
    "symbol": {label: "Symbol",},
    "title": {label: "Name",},
    "actions": {label: "Actions",},
    "createdAt": {label: "Created At",}
}

class StudentView extends React.Component{

    state = {
        data: {
            batches: [],
            onlineCourses: [],
        },
        _id: "",
        username: "",
        name: "",   
    }


    // =====================================================================================
    // Lifecycles
    // =====================================================================================

    componentDidMount(){
        this.fetchData()
        this.fetchCourses()
    }


    // ======================================================================================
    // REST Api Calls
    // ======================================================================================
    fetchData =async () => {
        const {id} = this.props.match.params
        if(!id) return this.props.history.replace("/admin/student/list")
        const {success, data} = await Client.api.get(`/admin/student/${id}`)
        this.setState({...data})
    } 
    fetchCourses = async() => {
        
        
    }

    enrollIntoCourse = async(courseId) => {
        const {id} = this.props.match.params
        const payload = {
            studentId: id,
            courseId
        }
        const {success, data} = await Client.api.post(`/admin/online-course/enroll`, payload)
        this.state.data.onlineCourses.push(data)
        this.setState({
            data: {...this.state.data}
        })
        return success
    }

    removeFromCourse = async( courseId) => {
        const {id} = this.props.match.params
        const payload = {
            studentId: id,
            courseId
        }
        try{
            const {success, data, message} = await Client.api.post(`/admin/online-course/enroll/remove`, payload)
            if(success){
                const index = this.state.data.onlineCourses.findIndex(v => v._id === courseId)
                this.state.data.onlineCourses.splice(index, 1)
                this.setState({
                    data: {...this.state.data}
                })
            }else{
                Client.alert.fire({icon: "error", body: message, confirmButton: true})
            }
        }catch(error){
            console.log({error})
            // Client.alert.fire({icon: "error", body: error.response.data.msg, confirmButton: true})
        }
    }

    // =====================================================================================
    // Handlers
    // =====================================================================================
    handleEnrollIntoCourse = (courseId) => {
        return this.enrollIntoCourse(courseId)
    }

    handleRemoveFromCourse = (courseId, e) => {
        e.preventDefault()
        this.removeFromCourse(courseId)
    }
    
    // ======================================================================================
    // UI handlers
    // ======================================================================================

    // ======================================================================================
    // Renderer helpers
    // ======================================================================================
    
    
    // ======================================================================================
    // Final Renderering
    // ======================================================================================
    render(){
        const user = this.state
        return <div className='student-view'>
            <table>
                <tbody>
                    <tr>
                        <td>Id</td>
                        <td>{user.username}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{user.name}</td>
                    </tr>

                </tbody>
            </table>
            <h3>Batches</h3>

            <h3>Online Courses</h3>
            
                
            <Components.data.Table headerMap={onlineCourseTablesLabels} headers={["index", "symbol", "title", "actions"]} 
                data={[
                    {
                        index: "#",
                        symbol: "New",
                        title: <OnlineCourseSelector onEnroll={this.handleEnrollIntoCourse}/>
                    },
                    ...user.data.onlineCourses.map((v,i)=>({
                        ...v, index: i+1,
                        actions: <Components.buttons.FlatButton onClick={this.handleRemoveFromCourse.bind(this, v._id)}>Remove</Components.buttons.FlatButton>
                    })),
                ]}
            />
          
        </div>
    }
}


export default StudentView