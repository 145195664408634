import List from './CourseList'
import Create from './CreateCourse'
import Details from './CourseDetails'
import Enroll from './EnrollCourse'
const exportObj = {
    Create,
    Details,
    Enroll,
    List,
}

export default exportObj