import Client from "."
import Api from "./api"
import clientLog from "./clientLog"

export class ClientSession{
    static typeAccountant = "USER_ACCOUNTANT"
    static typeAdmin = "USER_CLIENT_ADMIN"
    static typeCenterCoordinator = "USER_CENTER_COORDINATOR"
    static typeEntityAdmin = "USER_ENTITY_ADMIN"
    static typeStateCoordinator = "USER_STATE_COORDINATOR"
    static typeManagement = "USER_MANAGEMENT"
    static typeStudent = "USER_STUDENT"
    static typeTeacher = "USER_TEACHER"
    static _user = {}

    static loggedIn = false

// =========================================================================================
// Initializers
// =========================================================================================
    static async initialize(){
        this.token = this.token
        if(this.token){
            this.loggedIn = true
            const {success, data} = await Client.api.get('/user/login/details')
            if(success){
                this.user = data
            }
            this.loggedIn = success
        }
        return this.loggedIn
    }
// =========================================================================================
// Setters
// =========================================================================================
    
    static set user(userData){
        window.localStorage.setItem("USER_DATA", JSON.stringify(userData))
        this.userType = userData.kind
    }

    
    static set token (value) {
        window.localStorage.setItem("TOKEN", value)
    }


    static set userType( value ){
        switch(value){
            case this.typeAccountant:{window.localStorage.setItem("KIND", value); break;} 
            case this.typeAdmin:{window.localStorage.setItem("KIND", value); break;}
            case this.typeCenterCoordinator:{window.localStorage.setItem("KIND", value); break;} 
            case this.typeStateCoordinator:{window.localStorage.setItem("KIND", value); break;} 
            case this.typeEntityAdmin:{window.localStorage.setItem("KIND", value); break;}
            case this.typeManagement:{window.localStorage.setItem("KIND", value); break;} 
            case this.typeStudent:{window.localStorage.setItem("KIND", value); break;}
            case this.typeTeacher:{window.localStorage.setItem("KIND", value); break;}
            default:{
                window.localStorage.setItem("KIND", "");
            }
        }
        
    }

// =========================================================================================
// Getters
// =========================================================================================
    
    static get ADID(){
        const adid = window.localStorage.getItem("USER_ADID")
        return adid
    }

    static get initailToken () {
        return window.localStorage.getItem("INIT_TOKEN")
    }

    static get user(){
        const user = JSON.parse(window.localStorage.getItem("USER_DATA"))
        return user
    }
 

   
    static get ofAccountant(){ return this.typeAccountant === this.userType; }
    static get ofAdmin(){ return this.typeAdmin === this.userType; }
    static get ofCoOrdinator(){ return this.typeCenterCoordinator === this.userType; }
    static get ofStateCoOrdinator(){ return this.typeStateCoordinator === this.userType; }
    static get ofEntityAdmin(){ return this.typeEntityAdmin === this.userType; }
    static get ofManagement(){ return this.typeManagement === this.userType; }
    static get ofStudent(){ return this.typeStudent === this.userType; }
    static get ofTeacher(){ return this.typeTeacher === this.userType; }


    static get token () {
       return  window.localStorage.getItem("TOKEN")
    }


    static get userType(){
        const userType = window.localStorage.getItem("KIND")
        return userType
    }

    

    static get username(){
        const username = window.localStorage.getItem("USERNAME")
        return username;
    }

    static get userDisplayPicture(){
        const dp = window.localStorage.getItem("DP")
        return dp
    }

// =========================================================================================
// Methods
// =========================================================================================

    static async login(username, password){
        const payload = { username, password }
        this.logout()
        const {success, data, message, token} = await Api.post(`/user/login`, payload)
        if(success){
            this.token = token
            this.user = data
            // TODO Check the payload of the token and assign the initial userType
            this.loggedIn = true
            return this.loggedIn
        }else{
            clientLog.e("Login Error", message)
        }

        return false
    }

    static async logout(){
        
        this.userType = ""
        this.token = ""
        this.user = ""
        this.loggedIn = false 
        
    }
}