import { useState } from "react"
import TextInput from "./TextInput"



const Search = ({label, placeholder, value, onValueChange, render, show}) => {
    
    const [result, setResult] = useState([])
    const onChangeCallback = (result) => {
        if(result instanceof Array){
            setResult(result)
        }else{
            throw "Invalid return value. Return value onObjectChange should be either null or Array."
        }
    }

    const handleChange = async(e) => {
        e.preventDefault()
        onValueChange(e, onChangeCallback)        
    }
    
    return <div className="search-input">
        <TextInput leading={label} value={value} placeholder={placeholder} onChange={handleChange} />
        {show && result.length > 0 && 
            <div className="search-input__result">
                {
                    result.map(v => {
                        return <div className="search-input__result-item" key={v._id}>
                            { render ? render(v) : v.value }
                        </div>
                    })
                }
            </div>
        }
    </div>
}

export default Search