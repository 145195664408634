import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components'
import Client from '../../../services';


const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "username": {label: "username"},
    "name": {label: "Course Title"},
    "address": {label: "No of Levels"},
    "actions": {label: "Actions"},
}

const TeacherList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get(`/management/teacher/list?uid=6153835f3cb11685b424b82e`)
        if(success){
            const newList = data.map((user,i) => {
                return {
                    ...user, 
                    id: user._id, 
                    index: i, 
                    username: user.username ? user.username : 
                        <div>
                            <Components.buttons.FlatButton onClick={
                                async(e) => {
                                    e.preventDefault();
                                    const username = prompt("Enter username")
                                    if(username){
                                        const payload = {
                                            id : user._id,
                                            username
                                        }
                                        const {success} = await Client.api.put(`/management/teacher/${user._id}/username`, payload)
                                        if(success){
                                            setList(oldList => {
                                                oldList[i].username = username
                                                return [...oldList]
                                            })
                                        }
                                    }
                                }
                            }>Create</Components.buttons.FlatButton>
                        </div>,
                    actions: <button onClick={(e)=>props.history.push(`/management/teacher/${user._id}`)} >View</button>
                }
            })
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="teacher-list">    
        <h2>Teacher List</h2>
        <ul>
            <li><Link to="/management/teacher/register">Teacher Registration</Link></li>
        </ul> 

        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "username", "name", "address", "actions"]}
            data={list}
        />
    </div>
}

export default TeacherList