import React from 'react'
import Components from '../../../components'
import Client from '../../../services'
import PaymentList from './PaymentList'

class PaymentReport extends React.Component{
    
    state = {
        data: [],
        days: [],
        dailyStat: {x: [], y: []},
        stat:{
            nPayments: 0,
            perHeadAvg: 0,
            dailyAvg: 0,
            total: 0,
            royalty: 0,
        }
    }


// =====================================================================================================================
// Lifecycle Related
// =====================================================================================================================
    componentDidMount(){
        this.loadData()
        .then(this.processData)
    }


// =====================================================================================================================
// Api Call and Data process related
// =====================================================================================================================
    loadData = async() => {
        const {success, data} = await Client.api.get('/admin/accountant/fees/payment/list')
        if(success){
            const newData = data.map((payment, i) => {
                return {
                    index: i+1,
                    ...payment,
                    royalty: payment.royalty?  payment.royalty * 100 : 0,
                    royaltyAmt: payment.royalty? payment.paidAmount * payment.royalty : 0,
                    updatedAt: (new Date(payment.updatedAt)).toLocaleString()
                }
            })
            this.setState({data: newData})
        }else{
            this.setState({data: []})
        }
    }

    processData = async() => {
        const {data} = this.state
        const stat = {
            nPayments: data.length,
            total: data.reduce((acc, curr) => acc + curr.paidAmount, 0)
        }
        stat.perHeadAvg = Math.floor(100 * stat.total / stat.nPayments)/100;
        stat.royalty = 0
        const dailyStatMap = {}
        data.forEach(fee => {
            const date = new Date(fee.updatedAt)
            const key = Client.utility.getFormattedDateYYYYMMDD(fee.updatedAt)
            if(!dailyStatMap[key]) dailyStatMap[key] = {date: date.toDateString(), amt: 0, count: 0, royalty: 0}
            const dailyStat = dailyStatMap[key]
            dailyStat.amt += fee.paidAmount
            dailyStat.count += 1
            dailyStat.royalty += fee.royaltyAmt
            stat.royalty += fee.royaltyAmt
        })

        const dailyStat = {x: [], y:[]}
        
        dailyStat.y = Object.keys(dailyStatMap)
            .sort((prev, curr) => prev.localeCompare(curr) )
            .map(key => {
                dailyStat.x.push(dailyStatMap[key].date)
                return dailyStatMap[key]
            })
        stat.dailyAvg = Math.floor(100 * stat.total / dailyStat.length)/100;
        this.setState({dailyStat, stat})
    }

// =====================================================================================================================
// UI Handlers
// =====================================================================================================================

// =====================================================================================================================
// Renderers
// =====================================================================================================================

// =====================================================================================================================
// Final Rendering
// =====================================================================================================================

    render(){
        const {stat, dailyStat} = this.state
        const chartConfig = {
            height: 400,
            width: 400,
            showAxis: {x: false}
        }
        return <div className="payment-report">
            <div>Daily Avg: {stat.dailyAvg}</div>
            <div>Payment Count: {stat.nPayments}</div>
            <div>Avg payment per student: {stat.perHeadAvg}</div>
            <div>Total: {stat.total}</div>
            <div>Total Royalty: {stat.royalty}</div>
            <Components.charts.Line 
                {...chartConfig}
                title="Daily Payment (Rs.)"
                x={dailyStat.x}
                y={[
                    {
                        data: dailyStat.y.map(stat => {
                            return stat.amt
                        })
                    },
                ]} 
                
            />
            <Components.charts.Line 
                {...chartConfig}
                title="Daily Royalty (Rs)"
                x={dailyStat.x}
                showAxis={{x: false,}}
                y={[
                    {
                        data: dailyStat.y.map(stat => {
                            return stat.royalty
                        })
                    },
                ]} 
            />
            <Components.charts.Line 
                {...chartConfig}
                title="Daily Count (Unit)"
                x={dailyStat.x}
                y={[
                    {
                        data: dailyStat.y.map(stat => {
                            return stat.count
                        })
                    },
                ]} 
            />
            <PaymentList list={this.state.data} />
        </div>
    }

}

export default PaymentReport