import React from 'react'
import { Link } from 'react-router-dom'
import Client from '../../../services'
import MyNotifications from './MyNotifications'
import NotificationSent from './NotificationSent'
import SendNotification from './SendNotification'

class NotificaitonList extends React.Component{
    handleSendNewNotification = (e) => {
        e.preventDefault()
        const c = <SendNotification onSuccess={this.getData}/>
        Client.alert.fire({
            className: "notification-send--alert",
            title: "New Notfication",
            body: c
        })
    }
    render(){
        return <div className="notification-list">
            <ul>
                <li><Link to="/common/notification/sent">Sent Notifications</Link></li>
                <li><button onClick={this.handleSendNewNotification}>Send New Notification</button></li>
            </ul>
            <MyNotifications/>
        </div>
    }
}

export default NotificaitonList