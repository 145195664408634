import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Id",},
    "teacher": {label: "Teacher Name",},
    "subject": {label: "Subject",},
    "topic": {label: "Topic",},
    "scheduledAt": {label: "Date & Time",},
    "duration": {label: "Duration",},
}



const LectureList = ({list}) => {
    
    const headers = ["index", "_id", "teacher", "subject", "topic", "scheduledAt", "duration"]

    return <div className="student-list">
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default LectureList