import React from 'react';
import Components from '../../../components';
import Client from '../../../services';


class RegisterSchool extends React.Component{
    state = {
        name: "",
        address: "",
        dob: ""
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const {name, address} = this.state
        const payload = {name, address}
        const {success, message} = await Client.api.post('/admin/entity/school', payload)
        if (success){
            await Client.alert.fire({
                title: "Success",
                body: message || "New co ordintor succesfully registered.",
                confirmButton: true,
            })
            this.props.history.replace('/admin/entity/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "New co ordintor registration failed.",
                confirmButton: true
            })
        }
    }

    render(){
        const {name, address, dob} = this.state
        return <div className="new-coordinator">
            <Components.input.TextInput name="name" placeholder="Name" value={name} onChange={this.handleChange} />
            <Components.input.TextInput name="address" placeholder="Address" value={address} onChange={this.handleChange} />
            <Components.buttons.FlatButton onClick={this.handleSubmit}>Register</Components.buttons.FlatButton>
        </div>
    }
}

export default RegisterSchool