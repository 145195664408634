import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';

const headerMap = {
    "index": {label: "Sl.No.",},
    "username": {label: "Student Id",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "createdAt": {label: "Created At",},
    "actions": {label: "actions"}
}



const StudentList = ({list}) => {
    
    const headers = ["index", "username", "name", "address", "createdAt", "actions"]

    return <div className="student-list">
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default StudentList