import React, { useEffect, useState } from 'react'

import Components from '../../../../components'
import Client from '../../../../services'
import OnlineCourseDetailsView from './View'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Enrollment Id",},
    "username": {label: "Student Id",},
    "name": {label: "Name",},
}

class CourseDetailsRevamp extends React.Component{
    state = {
        course: {months: []},
        months: [],
        weeks: [],
        days: [],
        assets: [],
        assetDetails: {},
        selectedMonth: "",
        selectedWeek: "",
        selectedDay: "",
        selectedAsset: "",
    }
    componentDidMount(){
        this.loadDetails()
    }

    // ==============================================================================================
    // Api Calls
    // ==============================================================================================
    loadDetails = async () => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/admin/online-course/${id}`)
        if(success){
            this.setState({course: data, months: data.months})
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    fetchSelectedMonth = async () => {
        const id = this.state.selectedMonth
        const {success, data, message} = await Client.api.get(`/admin/online-course/month/${id}`)
        if(success){
            this.setState({
                weeks: data.weeks, 
                selectedWeek: "",
                selectedDay: "",
                selectedAsset: "",
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    fetchSelectedWeek = async () => {
        const id = this.state.selectedWeek
        const {success, data, message} = await Client.api.get(`/admin/online-course/month/week/${id}`)
        if(success){
            this.setState({
                days: data.days,
                selectedDay: "",
                selectedAsset: "",
            })
            
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    fetchSelectedDay = async() => {
        const id = this.state.selectedDay
        const {success, data, message} = await Client.api.get(`/admin/online-course/month/week/day/${id}`)
        if(success){
            this.setState({
                assets: data.assets,
                selectedAsset: "",
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    fetchSelectedAsset = async() => {
        try{
            const id = this.state.selectedAsset
            const {success, data, message} = await Client.api.delete(`/admin/online-course/month/week/day/asset/${id}`)
            if(success){
                console.log(data)
                this.props.onDeleteAsset && this.props.onDeleteAsset(id)
            }else{
                Client.alert.fire({
                    icon: "failure",
                    title: "Loading failed",
                    body: message,
                    confirmButton: true,
                })
            }

        }catch(err){
            Client.alert.fire({
                icon: "error",
                title: "Something went wrong!",
                body: err,
                confirmButton: true,
            })
        }
    }

    publishCourse = async ()=> {
        const course = this.state.course

        const payload = {
            courseId: course._id
        }

        const {success, data, message} = await Client.api.post(`/admin/online-course/${course._id}/publish`, payload)
        if(success){
            this.state.course.months.push(data)
            this.forceUpdate()
            Client.alert.fire({
                icon: "success",
                title: "Course has been published",
                confirmButton: true,
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Course Publish Failed",
                body: message,
                confirmButton: true,
            })
        }
    }
    
    addNewMonth = async (title)=> {
        const course = this.state.course

        const payload = {
            title,
            courseId: course._id
        }

        const {success, data, message} = await Client.api.post(`/admin/online-course/month`, payload)
        if(success){
            this.state.course.months.push(data)
            this.forceUpdate()
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Failed to add new month",
                body: message,
                confirmButton: true,
            })
        }
    }

    addNewWeek = async (title)=> {
        const monthId = this.state.selectedMonth

        const payload = {
            title,
            monthId: monthId
        }

        const {success, data, message} = await Client.api.post(`/admin/online-course/month/week`, payload)
        if(success){
            this.state.weeks.push(data)
            this.forceUpdate()
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Failed to add new week",
                body: message,
                confirmButton: true,
            })
        }
    }

    addNewDay = async (title)=> {
        const weekId = this.state.selectedWeek

        const payload = {
            title,
            weekId
        }

        const {success, data, message} = await Client.api.post(`/admin/online-course/month/week/day`, payload)
        if(success){
            this.state.days.push(data)
            this.forceUpdate()
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Failed to add new week",
                body: message,
                confirmButton: true,
            })
        }
    }

    addNewAsset = async (title, type, playType)=> {
        const dayId = this.state.selectedDay

        const payload = {
            title, dayId, type, playType
        }

        const {success, data, message} = await Client.api.post(`/admin/online-course/month/week/day/asset`, payload)
        if(success){
            this.state.assets.push(data)
            this.forceUpdate()
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Failed to add new week",
                body: message,
                confirmButton: true,
            })
        }
    }

    // ===========================================================================================
    // UI Handlers
    // ===========================================================================================
    handleChange = (e) => {
        e.preventDefault()
        const {name, type} =  e.target;
        let {value} = e.target

        this.setState({
            [name]: value
        }, ()=>this.handleFetchData(name))
    }

    handleFetchData = (name) => {
        switch(name){
            case "selectedMonth":{return this.fetchSelectedMonth()}
            case "selectedWeek":{return this.fetchSelectedWeek()}
            case "selectedDay":{return this.fetchSelectedDay()}
            // case "selectedAsset":{return this.fetchSelectedMonth()}
        }
    }

    handleShowMonthDetails= (id, e) => {
        e.preventDefault()
        
        this.setState({selectedMonth: id})
    }

    handlePublishCourse = async(e) => {
        e.preventDefault()
        await Client.alert.fire({
            icon: "warning",
            body: `Publishing a course will update the current course structure for all users. 
            Do want to publish or update the current course structure ?`,
            confirmButton: true,
            preConfirm: async () => {
                await this.publishCourse()
            }
        })
        
        
    }


    handleAddNewMonth = async() => {
        let title = ""
        const UI = (props) => {
            const [value, setValue]= useState("")
            return <Components.input.TextInput onChange={(e) =>{
                title = e.target.value
                console.log(e.target.value)
                setValue(title)
            }} value={value} />
        }
        const {isConfirmed} =  await Client.alert.fire({
            title: "Add new month",
            body: <UI/>,
            confirmButton: true,
            preConfirm: ()=>this.addNewMonth(title)
        })
        
    }

    handleAddNewWeek = async() => {
        let title = ""
        const UI = (props) => {
            const [value, setValue]= useState("")
            return <Components.input.TextInput onChange={(e) =>{
                title = e.target.value
                console.log(e.target.value)
                setValue(title)
            }} value={value} />
        }
        const {isConfirmed} =  await Client.alert.fire({
            title: "Add new week",
            body: <UI/>,
            confirmButton: true,
            preConfirm: ()=>this.addNewWeek(title)
        })
        
    }

    handleAddNewDay = async() => {
        let title = ""
        const UI = (props) => {
            const [value, setValue]= useState("")
            return <Components.input.TextInput onChange={(e) =>{
                title = e.target.value
                setValue(title)
            }} value={value} />
        }
        const {isConfirmed} =  await Client.alert.fire({
            title: "Add new day",
            body: <UI/>,
            confirmButton: true,
            preConfirm: ()=>this.addNewDay(title)
        })
        
    }

    handleAddNewAsset = async() => {
        let type = "VIDEO"
        let playType = "CLASSWORK"
        let title = ""
        const UI = (props) => {
            const [valueTitle, setValueTitle]= useState("")
            const [valueType, setValueType]= useState("")
            const [valuePlayType, setValuePlayType]= useState("")
            return <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Title</td>
                            <td>
                                <Components.input.TextInput 
                                    value={valueTitle}
                                    onChange={(e) =>{
                                        title = e.target.value
                                        setValueTitle(title)
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>
                                <Components.input.Select.Field 
                                    value={valueType}
                                    onChange={(e) =>{
                                        type = e.target.value
                                        setValueType(type)
                                    }} 
                                >
                                    <Components.input.Select.Option value="VIDEO">Video</Components.input.Select.Option>
                                    <Components.input.Select.Option value="ABACUS">Abacus</Components.input.Select.Option>
                                    <Components.input.Select.Option value="ABACUSTONUMBER">Abacus to Number</Components.input.Select.Option>
                                    <Components.input.Select.Option value="FINGERS">Fingers</Components.input.Select.Option>
                                    <Components.input.Select.Option value="MENTALABACUS">Mental Abacus</Components.input.Select.Option>
                                    <Components.input.Select.Option value="NUMBERTOABACUS">Number to Abacus</Components.input.Select.Option>
                                </Components.input.Select.Field>
                            </td>
                        </tr>
                        <tr>
                            <td>Play Type</td>
                            <td>
                                <Components.input.Select.Field 
                                    value={valuePlayType}
                                    onChange={(e) =>{
                                        playType = e.target.value
                                        setValuePlayType(playType)
                                    }} 
                                >
                                    <Components.input.Select.Option value="CLASSWORK">Classwork</Components.input.Select.Option>
                                    <Components.input.Select.Option value="HOMEWORK">Homework</Components.input.Select.Option>
                                </Components.input.Select.Field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        }
        const {isConfirmed} =  await Client.alert.fire({
            title: "New asset",
            body: <UI/>,
            confirmButton: true,
            preConfirm: ()=>this.addNewAsset(title, type, playType)
        })
        
    }

    handleDeleteAsset = (assetId)=>{
        const index = this.state.assets.findIndex(asset => asset._id === assetId)
        if(index > -1) {
            this.state.assets.splice(index, 1)
            this.setState({
                selectedAsset: ""
            })
        }
    }

    // ===================================================================================================
    // Render helper
    // ===================================================================================================
    

    // ====================================================================================================
    // Final rendering
    // ====================================================================================================

    render(){
        return <OnlineCourseDetailsView 
            {...this.state}
            onChangeMonth={this.handleChange}
            onChangeWeek={this.handleChange}
            onChangeDay={this.handleChange}
            onChangeAsset={this.handleChange}
            onPublishCourse={this.handlePublishCourse}
            onAddMonth={this.handleAddNewMonth}
            onAddWeek={this.handleAddNewWeek}
            onAddDay={this.handleAddNewDay}
            onAddAsset={this.handleAddNewAsset}
            onDeleteAsset={this.handleDeleteAsset}
        />
    }
}

export default CourseDetailsRevamp