import React from "react"
import Components from '../../../components'
import Client from "../../../services"

class EnrollStudentCourse extends React.Component{
    state = {
        course: "",
        student: "",
        
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { course, student } = this.state
        const payload = {
            courseId: course.trim(),
            studentId: student.trim(),
        }
        console.log(payload)
        const {success, data, message} = await Client.api.post('/management/student/enroll', payload)
        if (success){
            this.props.history.replace('/management/student/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "Failed to create the batch.",
                confirmButton: true
            })
        }
    }

    render(){
        

        return <div className="new-student-batch">
            <h2>Student course enrollment</h2>
            <Components.forms.SimpleForm>
                <table>
                    <tbody>
                        <tr>
                            <td>Course Id</td>
                            <td><Components.input.TextInput placeholder="Title" name="course"  value={this.state.course}  onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Student Id</td>
                            <td><Components.input.TextInput placeholder="Descriptionn" name="student" value={this.state.student} onChange={this.handleChange}/></td>
                        </tr>
                    </tbody>
                </table>
                <Components.buttons.FlatButton onClick={this.handleSubmit}>Submit</Components.buttons.FlatButton>
                
            </Components.forms.SimpleForm>
        </div>
    }
}

export default EnrollStudentCourse