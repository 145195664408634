import Swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal2);



class Swal{
    
    static cancel = () => {
        MySwal.clickCancel()
    }
    static close = () => {
        MySwal.close()
    }

    static fire = ({
        className,
        icon,
        title, body, footer, 
        
        didOpen, didClose, 
        
        preConfirm,preDeny,

        confirmButton,
        cancelButton,
        denyButton,
        closeButton,
        
        confirmButtonText,
        cancelButtonText,
        denyButtonText,

        reverseActions,
    })=>{
        
        return MySwal.fire({
            icon: icon,
            customClass: className? `myalert ${className}` : "myalert u-width-unset",
            
            title: title ? title : <div>Alert</div>,
            html: body,
            footer: footer,
            
            didOpen: didOpen,
            didClose: didClose,

            preDeny: preDeny,
            preConfirm: preConfirm,

            reverseButtons: !!reverseActions,
            showConfirmButton: confirmButton,
            showCancelButton: cancelButton,
            showDenyButton: denyButton,
            showCloseButton: closeButton,

            cancelButtonText: cancelButtonText? cancelButtonText : "Cancel",
            denyButtonText: denyButtonText? denyButtonText : "No",
            confirmButtonText: confirmButtonText? confirmButtonText : denyButton? "Yes" : "Ok",
        })
    }
}

export default Swal;