import clientLog from "./clientLog"

class UIContainers{
    static _root = null
    static _components = {}
    static _componentsWithKey = {}
//======================================================================================
// Register Methods
//=======================================================================================
    static registerRoot = (component) => {
        if(this._root) throw new Error("Root is already resitered")
        this._root = component
        clientLog.i("UIRoot/Register", component)
        return () =>{
            clientLog.i("UIRoot/Unregister", this._root)
            this._root = null
        }
    }

    static registerByName = (name, component) => {
        if(this._components[name]){
            // throw new Error("A component is already regitered with this name " + name)
            console.error("A component is already regitered with this name " + name)
            return
        } 
        this._components[name] = component
        clientLog.i("UIComponent/Register", {name, component})
        return () =>{
            clientLog.i("UIComponent/Unregister", name)
            delete this._components[name]
        }  
    }

    static registerByKey(name, key, component){
        if(this._componentsWithKey[name]) {
            if(this._componentsWithKey[name][key]) {
                // throw new Error("A component is already regitered with this name = " + name + "and key = " + key)
                console.error("A component is already regitered with this name = " + name + "and key = " + key)
                return 
            }
        }else{
            this._componentsWithKey[name] = {}
        }
        this._componentsWithKey[name][key] = component
        clientLog.i("UIComponent/Register", {name, key, component})

        return () =>{
            clientLog.i("UIComponent/Unregister", {name, key})
            delete this._componentsWithKey[name][key]
        }  
    }
//==================================================================================
// Getters
//==================================================================================
    static getByName(name){
        return this._components[name]
    }
    static getByKey(name, key){
        if(this._componentsWithKey[name]){
            return this._componentsWithKey[name][key]
        }
    }

    static 
}

export default UIContainers