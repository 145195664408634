import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Id",},
    "username": {label: "Username",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "createdAt": {label: "Created At",}
}



const AccountantList = ({...props}) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get('/management/accountant/list')
        if(success){
            const newList = data.map((user, i) => {
                return {
                    ...user,
                    index: i,
                    username: user.username ? user.username : 
                        <div>
                            <Components.buttons.FlatButton onClick={
                                async(e) => {
                                    e.preventDefault();
                                    const username = prompt("Enter username")
                                    if(username){
                                        const payload = {
                                            userId : user._id,
                                            username
                                        }
                                        const {success} = await Client.api.put(`/management/accountant/${user._id}/username`, payload)
                                        if(success){
                                            setList(oldList => {
                                                oldList[i].username = username
                                                return [...oldList]
                                            })
                                        }
                                    }
                                }
                            }>Create</Components.buttons.FlatButton>
                        </div>
                }
            })
            setList(newList)
        }else{
            setList([])
        }
    },[])

    const headers = ["index", "username", "name", "address", "createdAt"]


    return <div className="student-list">
        <h2>Student List</h2>

        <ul>
            <li><Link to="/management/accountant/register">Register Accountant</Link></li>
        </ul>
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default AccountantList