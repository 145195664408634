import React, { useEffect, useState } from 'react';
import Components from '../../../components';
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Payment Id",},
    "amount": {label: "Amount",},
    "late": {label: "(+) Late",},
    "discount": {label: "(-) Less",},
    "paidAmount": {label: "(=) Paid",},
    "updatedAt": {label: "Date & Time",},
    "_eid": {label: "School",},
    "royalty": {label: "Royalty (%)",},
    "royaltyAmt": {label: "Royalty (Rs)",},
}


const PaymentList = ({list=[]}) => {
    

    const headers = ["index", "_id", "amount", "late",  "discount", "paidAmount", "updatedAt", "_eid", "royalty", "royaltyAmt"]

    return <div className="payment-list">     
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default PaymentList