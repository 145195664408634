import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components'
import Client from '../../../services';


const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "name": {label: "Name"},
    "address": {label: "Address"},
    "actions": {label: "Actions"},
}

const TeacherList = ({list}) => {
    
    

    return <div className="teacher-list"> 
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "name", "address", "actions"]}
            data={list}
        />
    </div>
}

export default TeacherList