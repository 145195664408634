import { useState } from "react"
import Components from '../../components'

const CourseForm = (props) => {
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [duration, setDuration] = useState("")
    const [nLevels, setNLevels] = useState(1)
    const [cost, setCost] = useState("")

    return <div className="student-registration">
        <Components.forms.SimpleForm>
            <Components.input.TextInput placeholder="Title"  value={name}  />
            <Components.input.TextInput placeholder="Descriptionn" value={address} />
            <Components.input.TextInput placeholder="Duration"  value={duration}  />
            <Components.input.TextInput placeholder="No of Levels" value={nLevels} />
            <Components.input.TextInput placeholder="Cost"  value={cost}  />
            <Components.buttons.FlatButton>Submit</Components.buttons.FlatButton>
        </Components.forms.SimpleForm>
    </div>
}

export default CourseForm