import React from 'react'
import { Link } from 'react-router-dom'
import Client from '../services'

const SideNav = (props) => {

    let navList = null

    switch(Client.session.userType){
        case Client.session.typeAccountant:{
            navList = <>
                <li><Link to="/accountant/">Student fees payment</Link></li>
                {/* <li><Link to="/management/accountant/list">Accountant List</Link></li>
                <li><Link to="/management/student/list">Student List</Link></li>
                <li><Link to="/management/teacher/list">Teacher List</Link></li>
                <li><Link to="/management/course/list">Course List</Link></li>
                <li><Link to="/management/course-batch/list">Course Batch List</Link></li>
                <li><Link to="/management/schedule/lecture/list">Lectures List</Link></li> */}
            </>;
            break;
        }
        case Client.session.typeAdmin:{
            navList = <>
                {/* <li><Link to="/management/teacher/register">Register Teacher</Link></li> */}
                {/* <li><Link to="/admin/coordinator/list">Co-ordinator List</Link></li> */}
                <li><Link to="/admin/entity/list">Enity List</Link></li>
                <li><Link to="/admin/course/list">Course List</Link></li>
                <li><Link to="/admin/online-course/list">Online Course</Link></li>
                <li><Link to="/admin/student/list">Student List</Link></li>
                <li><Link to="/admin/user/list">User List</Link></li>
                <li><Link to="/admin/accountant/payment/report">Payment Report</Link></li>
            </>;
            break;
        }
        case Client.session.typeCenterCoordinator:{
            navList = <>
                {/* <li><Link to="/management/teacher/register">Register Teacher</Link></li> */}
                {/* <li><Link to="/admin/student/list">Student List</Link></li> */}
                <li><Link to="/coordinator/user/list">User List</Link></li>
            </>;
            break;
        }
        case Client.session.typeStateCoordinator:{
            navList = <>
                {/* <li><Link to="/management/teacher/register">Register Teacher</Link></li> */}
                {/* <li><Link to="/admin/student/list">Student List</Link></li> */}
                <li><Link to="/state-coordinator/entity/list">Entity List</Link></li>
                <li><Link to="/state-coordinator/user/list">User List</Link></li>
            </>;
            break;
        }
        case Client.session.typeManagement:{
            navList = <>
                {/* <li><Link to="/management/teacher/register">Register Teacher</Link></li> */}
                <li><Link to="/management/accountant/list">Accountant List</Link></li>
                <li><Link to="/management/student/list">Student List</Link></li>
                <li><Link to="/management/teacher/list">Teacher List</Link></li>
                <li><Link to="/management/course/list">Course List</Link></li>
                <li><Link to="/management/course-batch/list">Course Batch List</Link></li>
                <li><Link to="/management/schedule/lecture/list">Lectures List</Link></li>
            </>;
            break;
        }
        case Client.session.typeStudent:{
            navList = <>
                <li><Link to="/student/lecture/list">Upcoming Lectures</Link></li>
                <li><Link to="/student/payment/list">Payment</Link></li>
            </>;
            break;
        }
        case Client.session.typeTeacher:{
            navList = <>
                <li><Link to="/teacher/course-batch/list">Course Batch List</Link></li>
                <li><Link to="/teacher/schedule/list">Assigned Schedule</Link></li>
            </>;
            break;
        }

    }

    return <div className="side-nav">
        <ul>
            <li><Link to="/common/notification">Notifiactions</Link></li>
        </ul>
        <ul>
            <li><Link to="/dashboard">Dashboard</Link></li>
            {navList}
        </ul>

        {/* <ul>
            <li><Link to="/dashboard"><div>Dashboard</div></Link></li>
            <li><Link to="/admin"><div>Admin</div></Link></li>
            <li><Link to="/management"><div>Management</div></Link></li>
            <li><Link to="/accountant"><div>Accountant</div></Link></li>
            <li><Link to="/teacher"><div>Teachers</div></Link></li>
            <li><Link to="/student"><div>Students</div></Link></li>
        </ul> */}
    </div>

}

export default SideNav