import React from 'react'
import Components from '../../../components'
import NotificationTile from '../../../pageComponents/Notification/NotificationTile'
import Client from '../../../services'
import SendNotification from './SendNotification'


class NotificationSent extends React.Component{
    state = {
        list: []
    }
    componentDidMount(){
        this.getData()
    }

    getData = async () => {
        const {data, success} = await Client.api.get('/common/notification/sent')
        if(success){
            const list = data.map(v => {
               return {
                   ...v,
                   from: v.from ? v.from : v._by,
                   to: v.to ? v.to : v._uid,
               }
            })
            this.setState({list})
        }
    }

    handleSendNewNotification = (e) => {
        e.preventDefault()
        const c = <SendNotification onSuccess={this.getData}/>
        Client.alert.fire({
            className: "notification-send--alert",
            title: "New Notfication",
            body: c
        })
    }


    render(){
        return <div className="notification-sent">
            <div className="u-flex--row">
                <h3>Notification Sent by Me</h3>
                <Components.buttons.FlatButton onClick={this.handleSendNewNotification}>New</Components.buttons.FlatButton>
            </div>
            {
                this.state.list.map(v => {
                    return <NotificationTile key={v._id} {...v} />
                })
            }
        </div>
    }
}

export default NotificationSent