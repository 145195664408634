import Accountant from './Accountant'
import CoOrdinator from './CoOrdinator'
import Course from './Course'
import OnlineCourse from './OnlineCourse'
import Entity from './Entity'
import Dashboard from './Dashboard'
import Student from './Student'
import Users from './Users'
const exportObj = {
    Accountant,
    CoOrdinator,
    Course,
    Dashboard,
    Entity,
    OnlineCourse,
    Student,
    Users,
}

export default exportObj