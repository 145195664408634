import React from 'react'
import Client from '../../../services'

class StudentBatchDetails extends React.Component{
    state = {
        user: {}, 
        teacher: {}
    }
    async componentDidMount(){
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/management/teacher/${id}`)
        if(success){
            const {data: teacher, ...user} = data
            this.setState({
                user, teacher
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    render(){
        const {user, teacher} = this.state
        return <div className="student-batch-details">
            <h2>Teacher Details</h2>
            <div>Teacher Id: {teacher._id}</div>
            <div>Name: {user.name}</div>
            <div>Address: {user.address}</div>
        </div>
    }
}

export default StudentBatchDetails