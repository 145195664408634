import axios from 'axios';
import clientLog from './clientLog';
import { ClientSession } from './session';
import Swal from './swal';


// ==========================================================================================



/**
 * This function returns a complete url based on the SignIn status
 * @param {string} path 
 * @returns url string to send a request to backend
 */
const getUrl = (path) => {

    return `/api${path}`
}



const Api = {
    axios: axios,
    getUrl: getUrl,

   
    /**
     * This functions used to send a GET request to the backend and 
     * returns an object in appropriate format that is used in backend 
     * alogn with the axios respose object
     * @param {string} path relative path of the end point
     * @param {object} config request configuration
     * @returns Response
     */
    get: async(path, config) => {
        const url = getUrl(path)
        try{
            config = config || {}
            if(ClientSession.loggedIn){
                if(config.headers){
                    config.headers.Authorization = `Bearer ${ClientSession.token}`
                }else{
                    config.headers= {
                        Authorization: `Bearer ${ClientSession.token}`,
                    }
                }
            }
            const res = await axios.get(url, config)
            clientLog.api("Success/GET/Api", url, res)
            await executeServerAction(res.data.action)
            const response = {
                response: res,
                success: res.data.success,
                data: res.data.data,
                message: res.data.msg,
                token: res.data.token
            }
            return response
        }catch(err){

            const res = err.response
            clientLog.api("Error/GET/Api", url, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: false,
                data: res.data.data,
                message: res.data.msg,
            }
        }
    },
    post: async(path, data, config) => {
        const url = getUrl(path)
        try{
            config = config || {}
            if(ClientSession.loggedIn){
                if(config.headers){
                    config.headers.Authorization = `Bearer ${ClientSession.token}`
                }else{
                    config.headers= {
                        Authorization: `Bearer ${ClientSession.token}`,
                    }
                }
            }
            const res = await axios.post(url, data, config)
            clientLog.api("Success/POST/Api", url, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: res.data.success,
                data: res.data.data,
                message: res.data.msg,
                token: res.data.token
            }
        }catch(err){

            const res = err.response
            clientLog.api("Error/POST/Api", path, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: false,
                data: res.data.data,
                message: res.data.msg
            }
        }
        
    },
    put: async(path, data, config) => {
        const url = getUrl(path)
        try{
            config = config || {}
            if(ClientSession.loggedIn){
                if(config.headers){
                    config.headers.Authorization = `Bearer ${ClientSession.token}`
                }else{
                    config.headers= {
                        Authorization: `Bearer ${ClientSession.token}`,
                    }
                }
            }
            const res = await axios.put(url, data, config)
            clientLog.api("Success/PUT/Api", url, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: res.data.success,
                data: res.data.data,
                message: res.data.msg,
                token: res.data.token
            }
        }catch(err){

            const res = err.response
            clientLog.api("Error/PUT/Api", path, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: false,
                data: res.data.data,
                message: res.data.msg
            }
        }
        
    },
    delete: async(path, config) => {
        const url = getUrl(path)
        try{
            config = config || {}
            if(ClientSession.loggedIn){
                if(config.headers){
                    config.headers.Authorization = `Bearer ${ClientSession.token}`
                }else{
                    config.headers= {
                        Authorization: `Bearer ${ClientSession.token}`,
                    }
                }
            }
            const res = await axios.delete(url, config)
            await executeServerAction(res.data.action)
            clientLog.api("Success/DELETE/Api", url, res)
            return {
                response: res,
                success: res.data.success,
                data: res.data.data,
                message: res.data.msg,
                token: res.data.token
            }
        }catch(err){

            const res = err.response
            clientLog.api("Error/DELETE/Api", url, res)
            await executeServerAction(res.data.action)
            return {
                response: res,
                success: false,
                data: res.data.data,
                message: res.data.msg,
            }
        }
    },

}

const executeServerAction = async(action) => {
    switch(action){
        case "LOGOUT":{
            await Swal.fire({
                title: "Information",
                body: "Session Expired",
                confirmButton: true,
                confirmButtonComponent: "Okay & Reload"
            })
            ClientSession.resetUser()
            window.history.go('/tradefinance')
            return
        }
    }
}

export default Api;