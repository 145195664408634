import React, { useEffect, useState } from "react";
import Components from "../../../components";
import Client from "../../../services";

const AddTeacher = ({onClickTeacher}) => {
    const [searching, setSearching] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [value, setValue] = useState("")
    const [teacher, setTeacher] = useState(null)
    let timeoutId = -1
    const searchTeacher = async (e, callback) => {
        const { value } = e.target
        if (showResult === false) setShowResult(true)
        clearTimeout(timeoutId)
        // setSid(value)
        if (value.length < 3) {
            callback([])
            // setShowList(false)
        }
        timeoutId = setTimeout(async () => {
            // setSearching(true)
            const { success, data } = await Client.api.get('/management/teacher/search?qname=' + value)
            if (success) {
                callback(data)
                // setShowList(data.length > 0)
            } else {
                callback([])
                // setShowList(false)
            }
            setSearching(false)
        }, 500)
    }

    const getTeacherData = async (id) => {
        setShowResult(false)        
        const { success, data } = await Client.api.get('/management/teacher/' + id)
        if (success) {
            setTeacher(data)
        } else {
            setTeacher(null)
        }
    }
    useEffect(() => {
        console.log(teacher)
        onClickTeacher( teacher ?  teacher._id : "")
    }, [teacher])
    return <div className="course-batch__add-teacher">
        <table>
            <tbody>
                <tr>
                    <td>Teacher Id</td>
                    <td>
                        <Components.input.Search
                            placeholder="Search teacher name or id"
                            onValueChange={searchTeacher}
                            show={showResult}
                            render={(teacher) => {
                                console.log(teacher)
                                return <div
                                    className="course-batch__search-result-item"
                                    onClick={() => {
                                        // setSid(teacher._id)
                                        getTeacherData(teacher._id)
                                    }}
                                >
                                    <div>{teacher.name}</div>
                                    <div>{teacher.username}</div>
                                </div>
                            }}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        {
            teacher && 
            <table>
                <tbody>
                    <tr>
                        <td>Teacher Id</td>
                        <td>{teacher.username}</td>
                    </tr>
                    <tr>
                        <td>Teacher Name</td>
                        <td>{teacher.name}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{teacher.address}</td>
                    </tr>
                </tbody>
            </table>
        }
    </div>
}

export default AddTeacher