import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components'
import Client from '../../../services';


const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "title": {label: "Course Title"},
    "description": {label: "Description"},
    "nStudents": {label: "No of Students"},
    "nTeachers": {label: "No of Teachers"},
    "actions": {label: "Actions"},
}

const CourseList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        
        const {success, data} = await Client.api.get(`/teacher/course/batch/list?uid=615df1b7e287a0c0098f5193`)
        if(success){
            const newList = data.map((v,i) => {
                return {...v, index: i, actions: <button onClick={(e)=>props.history.push(`/teacher/course-batch/${v._id}`)} >View</button>}
            })
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="student-list">    
        <h3>Course Batches</h3>
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "title", "description", "nStudents", "nTeachers",  "actions"]}
            data={list}
        />
    </div>
}

export default CourseList