import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = ({homePath="/", logo, left, center, right, ...props}) => {
    return <div className="navbar">
        <Link className="navbar__logo" to={homePath}>{logo}</Link>    
        <div className="navbar__left">{left}</div>
        <div className="navbar__center">{center}</div>
        <div className="navbar__right">{right}</div>
    </div>
}

export default Navbar