import React from "react"
import Components from '../../../components'
import Client from "../../../services"
import qs from 'query-string'



class NewStudentBatch extends React.Component{
    state = {
        title: "",
        description: "",
        cid: ""
    }

    componentDidMount(){
        const queryString = this.props.location.search
        const {cid} = qs.parse(queryString)
        this.setState({cid})
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { title, description, cid } = this.state
        const payload = {
            uid: "6153835f3cb11685b424b82e",
            title, description, cid
        }
        
        const {success, data, message} = await Client.api.post('/management/course/batch', payload)
        if (success){
            this.props.history.replace('/management/course-batch/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "Failed to create the batch.",
                confirmButton: true
            })
        }
    }

    render(){
        

        return <div className="new-student-batch">
            <h2>Create course batch</h2>
            <Components.forms.SimpleForm>
                <Components.input.TextInput placeholder="Title" name="title"  value={this.state.title}  onChange={this.handleChange}/>
                <Components.input.TextInput placeholder="Descriptionn" name="description" value={this.state.description} onChange={this.handleChange}/>
                <Components.buttons.FlatButton onClick={this.handleSubmit}>Submit</Components.buttons.FlatButton>
            </Components.forms.SimpleForm>
        </div>
    }
}

export default NewStudentBatch