import React from "react"
import Components from '../../../components'
import Client from "../../../services"

class NewStudentBatch extends React.Component{
    state = {
        name: "",
        address: "",
        dob: "",
        email: ""
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const {name, address, dob, email} = this.state
        const payload = {name, address, dob, uid: "6153835f3cb11685b424b82e", email}
        const {success, data, message} = await Client.api.post('/management/teacher/register', payload)
        if (success){
            await Client.alert.fire({
                icon: "success",
                title: "Success",
                body: message || "Student registration successfull.",
                confirmButton: true
            })
            this.props.history.replace('/management/teacher/list')

        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "New co ordintor registration failed.",
                confirmButton: true
            })
        }
    }

    render(){
        const {name, address, dob, email} = this.state
        return <div className="teacher-registration">
            <h2>Teacher Registration</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td><Components.input.TextInput name="name" placeholder="Name" value={name} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><Components.input.TextInput name="email" type="email" placeholder="Email address" value={email} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Date of Birth</td>
                        <td><Components.input.TextInput type="date" name="dob" placeholder="Date of birth" value={dob} onChange={this.handleChange}/></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td><Components.input.TextInput name="address" placeholder="Address" value={address} onChange={this.handleChange} /></td>
                    </tr>
                </tbody>
            </table>
            
            
            
            <Components.buttons.FlatButton onClick={this.handleSubmit}>Register</Components.buttons.FlatButton>
        </div>
    }
}

export default NewStudentBatch