import Dashboard from "./Dashboard";
import MakePayment from "./MakePayment";
import FeesCollection from "./FeesCollection";

const exportObj = {
    Dashboard,
    MakePayment,
    FeesCollection
}

export default exportObj