import React from "react"
import Components from '../../../components'
import Client from "../../../services"

class CreateCourse extends React.Component{
    state = {
        symbol: "",
        title: "",
        description: "",
        nLevel: "",
        duration: "",
        cost: "",
        startingFrom: "",
        
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { title, description, nLevel, duration, cost, startingFrom, symbol} = this.state
        const payload = {
            title, description, nLevel, duration, cost, startingFrom, symbol
        }
        console.log(payload)
        const {success, data, message} = await Client.api.post('/admin/course', payload)
        if (success){
            this.props.history.replace('/admin/course/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "Failed to create the batch.",
                confirmButton: true
            })
        }
    }

    render(){
        

        return <div className="new-student-batch">
            <h2>Initialize New Course</h2>
            <Components.forms.SimpleForm>
                <table>
                    <tbody>
                        <tr>
                            <td>Course Code</td>
                            <td><Components.input.TextInput placeholder="Symbol" name="symbol"  value={this.state.symbol}  onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Title</td>
                            <td><Components.input.TextInput placeholder="Title" name="title"  value={this.state.title}  onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td><Components.input.TextInput placeholder="Descriptionn" name="description" value={this.state.description} onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>No of Levels</td>
                            <td><Components.input.TextInput placeholder="No of Levels" name="nLevel" type="number" value={this.state.nLevel} onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td><Components.input.TextInput placeholder="Duration of Each Level" name="duration" type="number"  value={this.state.duration}  onChange={this.handleChange}/></td>
                            <td>Month(s)</td>
                        </tr>
                        <tr>
                            <td>Cost</td>
                            <td><Components.input.TextInput placeholder="Cost" name="cost" type="number" value={this.state.cost}  onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Starting From</td>
                            <td><Components.input.TextInput placeholder="Starting From" name="startingFrom" type="date" value={this.state.startingFrom}  onChange={this.handleChange}/></td>
                        </tr>
                    </tbody>
                </table>
                <Components.buttons.FlatButton onClick={this.handleSubmit}>Submit</Components.buttons.FlatButton>
                
            </Components.forms.SimpleForm>
        </div>
    }
}

export default CreateCourse