import React, { useEffect, useState } from 'react'

import Components from '../../../components'
import Client from '../../../services'
import EnrollCourse from './EnrollCourse'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Enrollment Id",},
    "username": {label: "Student Id",},
    "name": {label: "Name",},
}

class CourseDetails extends React.Component{
    state = {
        creator: {},
        school: {},
        course: {},
        students: []
    }
    componentDidMount(){
        this.loadDetails()
        this.loadStudents()

    }

    loadDetails = async () => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/common/course/${id}`)
        if(success){
            const {_by: creator, _sid: school, ...course} = data
            console.log({ creator, course})
            this.setState({
                creator, course
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    loadStudents = async () => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/management/course/${id}/students`)
        if(success){
            const list = data.map((v, i) => {
                return {
                    index: i+1,
                    _id: v._id,
                    name: v._uid.name,
                    username: v._uid.username,
                }
            })
            this.setState({
                students: list
            })
        }
    }

    handleEnrollStudent = (e) => {
        e.preventDefault()
        let studentId = ""
        let feesDueDate = ""
        let monthlyFees = this.state.course.cost
        const handleClickStudent = (id) => studentId = id
        const handleChangeFirstDueDate = (value) => feesDueDate = value
        const handleChangeMonthlyFees = (value) => monthlyFees = value
        Client.alert.fire({
            title: "Enroll Student",
            body: <EnrollCourse 
                courseFees={this.state.course.cost}
                onChangeMonthlyFees={handleChangeMonthlyFees}
                onClickStudent={handleClickStudent} 
                courseStartingFrom={Client.utility.getFormattedDateYYYYMMDD(this.state.course.startingFrom)}
                onChageDueDate={handleChangeFirstDueDate}
            />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const {batch} = this.state
                const {id} = this.props.match.params

                const payload = {
                    studentId: studentId.trim(),
                    courseId: id.trim(),
                    feesDueDate,
                    monthlyFees
                }
                const {success, data, message} = await Client.api.post(`/management/student/enroll`, payload)
                if (success){
                    const newEnrollment = {
                        index: this.state.students.length + 1,
                        _id: data.courseEnrollment._id,
                        username: data.courseEnrollment._uid.username,
                        name: data.courseEnrollment._uid.name,
                    }
                    this.setState({students: [...this.state.students, newEnrollment]})
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Student enrolled succesfully.",
                        confirmButton: true,
                    })
                    
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "failed to enroll student.",
                        confirmButton: true
                    })
                }
            }
        })
    }

    handleCreateNewBatch = () => {
        const {id} = this.props.match.params
        this.props.history.push('/management/course-batch/create?cid='+id)
    }

    render(){
        const headers = ["index", "_id", "username", "name"]
        const {school, course} = this.state
        return <div className="student-batch-details">
            <h2>Course Details</h2>
            <Components.buttons.FlatButton onClick={this.handleCreateNewBatch}>Create new batch</Components.buttons.FlatButton>
            <Components.buttons.FlatButton onClick={this.handleEnrollStudent}>Enroll New Student</Components.buttons.FlatButton>
            <div>Institution Name: {school.name}</div>
            <div>Address: {school.address}</div>
            <div>Batch Title: {course.title}</div>
            <div>Batch Description: {course.description}</div>
            <div>Starting Date: {course.startingFrom}</div>
            <div>No of Levels: {course.nLevel}</div>
            <div>Duration of each level: {course.duration} month</div>
            <div>Cost per month: Rs. {course.cost}/-</div>

            <Components.data.Table headerMap={headerMap} headers={headers} data={this.state.students}/>

        </div>
    }
}

export default CourseDetails