import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Components from '../../../components'
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "teacher": {label: "Teacher"},
    "subject": {label: "Subject"},
    "topic": {label: "topic"},
    "scheduledAt": {label: "Date & Time"},
    "startTime": {label: "Started at"},
    "isPresent": {label: "Present/Absent"},
    "duration": {label: "Duration (minutes)"},
}

const LectureList = (props) => {
    
    var [list, setList] = useState([])
    const userId = Client.session.user._id
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get(`/student/lecture/list`)
        if(success){
            const newList = data.map((v,i) => {
                const {data:{attendance, ...lecture}, _uid: teacher, ...schedule} = v
                console.log(attendance)
                return {
                    ...schedule, ...lecture,
                    scheduledAt: (new Date(schedule.scheduledAt)).toLocaleString(),
                    teacher: <div key={teacher._id}>{teacher.name}</div>,
                    startTime: (new Date(schedule.startTime)).toLocaleDateString(),
                    isPresent: !attendance  ? 
                        "-" : 
                        (
                            attendance.attendees[0]? 
                            (attendance.attendees[0].checkIn ? "Present" : "Absent") 
                            : "Absent"
                        ),
                    index: i, actions: <button onClick={(e)=>props.history.push(`/management/course-batch/${v._id}`)} >View</button>}
            })
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="student-list">    
        <h4>Lectures</h4>
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "teacher", "subject", "topic", "scheduledAt", "duration", "startTime", "isPresent"]}
            data={list}
        />
    </div>
}

export default LectureList