import React, { useEffect, useState } from 'react'

import Components from '../../../components'
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Enrollment Id",},
    "username": {label: "Student Id",},
    "name": {label: "Name",},
}

class CourseDetails extends React.Component{
    state = {
        creator: {},
        school: {},
        studentBatch: {},
        students: []
    }
    componentDidMount(){
        this.loadDetails()
        this.loadStudents()

    }

    loadDetails = async () => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/common/course/${id}`)
        if(success){
            const {_by: creator, _sid: school, ...studentBatch} = data
            console.log({ creator, studentBatch})
            this.setState({
                creator, studentBatch
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    loadStudents = async () => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.get(`/admin/course/${id}/students`)
        if(success){
            const list = data.map((v, i) => {
                return {
                    index: i+1,
                    _id: v._id,
                    name: v._uid.name,
                    username: v._uid.username,
                }
            })
            this.setState({
                students: list
            })
        }
    }

    

    

    render(){
        const headers = ["index", "_id", "username", "name"]
        const {school, studentBatch} = this.state
        return <div className="student-batch-details">
            <h2>Course Details</h2>
            
            <div>Institution Name: {school.name}</div>
            <div>Address: {school.address}</div>
            <div>Batch Title: {studentBatch.title}</div>
            <div>Batch Description: {studentBatch.description}</div>
            <div>Starting Date: {studentBatch.startingFrom}</div>
            <div>No of Levels: {studentBatch.nLevel}</div>
            <div>Duration of each level: {studentBatch.duration} month</div>
            <div>Cost per month: Rs. {studentBatch.cost}/-</div>

            <Components.data.Table headerMap={headerMap} headers={headers} data={this.state.students}/>

        </div>
    }
}

export default CourseDetails