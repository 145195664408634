import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Components from '../../components';
import Client from '../../services';

class LoginForm extends React.Component{
    state = {
        username: "",
        password: "",
        otp: "",
        resetPassword: false,
        otpSent: false,
    }

    constructor(props){
        super(props)
        if(!props.onComplete) throw "onComplete function is required."
    }

    // ========================================================================================================================
    // Lifecycle Methods
    // ========================================================================================================================

    // ========================================================================================================================
    // Api Calls
    // ========================================================================================================================
    login = async() =>{
        const {username, password} = this.state
        const loggedIn = await Client.session.login(username, password)
        this.props.onComplete(loggedIn)
    }
    sendOtp = async() => {
        const {username} = this.state
        const url = "/user/login/forgot-password"
        const {success, message} = await Client.api.post(url, {username})
        Client.alert.fire({
            icon: success? "success" : "failure",
            body: message,
            confirmButton: true    
        })
        return success
    }
    resetPassword = async() => {
        const {username, password, otp} = this.state
        const url = "/user/login/forgot-password/reset"
        const {success, message} = await Client.api.post(url, {username, password, otp})
        Client.alert.fire({
            icon: success? "success" : "failure",
            body: message,
            confirmButton: true    
        })
        return success
    }
    // ========================================================================================================================
    // UI Handlers
    // ========================================================================================================================
    handleChange = (e) => {
        const {name, type} = e.target
        let {value} = e.target
        e.preventDefault()
        this.setState({
            [name]: value
        })
    }
    
    handleLogin = (e) => {
        e.preventDefault()
        this.login()
    }

    handleResetPassword = async(e) => {
        e.preventDefault()
        const success = await  this.resetPassword()
        if(success){
            this.setState({
                username: "",
                password: "",
                otp: "",
                resetPassword: false,
                otpSent: false,
            })
        }
    }

    handleSendOtp = async (e) => {
        e.preventDefault()
        const success = await this.sendOtp()
        if(!this.state.otpSent && success){
            this.setState({
                otpSent: success
            })
        }
    }
    handleToggleResetPassword = (e) => {
        e.preventDefault()
        this.setState({
            resetPassword: !this.state.resetPassword
        })
    }
    // ========================================================================================================================
    // Renderer
    // ========================================================================================================================

    // ========================================================================================================================
    // Final rendering
    // ========================================================================================================================
    render(){
        return  <div className="login-form">
            
            <div className="login-form__fields" >
                <center><h3>3Q Abacus ERP</h3></center>
                {
                    this.state.resetPassword?
                    <Fragment>
                        <Components.input.TextInput header="Login Id" placeholder="User Id / Registration No " name="username" value={this.state.username} onChange={this.handleChange} />
                        {
                            this.state.otpSent ?
                            <Fragment>
                                <Components.input.TextInput header="OTP" placeholder="Enter OTP" name="otp" value={this.state.otp} onChange={this.handleChange} />
                                <Components.input.TextInput header="Password" placeholder="Password" type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                <Components.buttons.FlatButton onClick={this.handleResetPassword}>Reset password</Components.buttons.FlatButton>
                            </Fragment>
                            :
                            <Components.buttons.FlatButton onClick={this.handleSendOtp}>Send OTP</Components.buttons.FlatButton>
                        }
                        {
                            this.state.sendOtp &&
                            <p>Do you want to login?<Components.buttons.FlatButton onClick={this.handleToggleResetPassword}>Click here</Components.buttons.FlatButton></p>
                        }

                    </Fragment>
                    :
                    <Fragment>
                        <Components.input.TextInput header="Login Id" placeholder="User Id / Registration No " name="username" value={this.state.username} onChange={this.handleChange} />
                        <Components.input.TextInput header="Password" placeholder="Password" type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                        <Components.buttons.FlatButton onClick={this.handleLogin}>Login</Components.buttons.FlatButton>
                        <p>Trouble to login?<Components.buttons.FlatButton onClick={this.handleToggleResetPassword}>Forgot Password</Components.buttons.FlatButton></p>
                    </Fragment>

                }
                
            </div>
            <div className="login-form__border-line"></div>
            <div className="login-form__logo">
                <img src="/images/Logo_3Q.png" alt="3Q Abacus Logo" />
                <h3>We create Success</h3>
            </div>
            
        </div>
    }
}

export default LoginForm