import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Id",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "createdAt": {label: "Created At",}
}



const StudentList = ({list}) => {
    
    const headers = ["index", "_id", "name", "address", "createdAt"]

    return <div className="student-list">
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default StudentList