import React, { useEffect, useState } from 'react'
import NotificationTile from './/NotificationTile'
import Client from '../../services'

const ShowNotifications = ({url, onDataFetched, ...props}) => {
    const [list, setList] = useState([])
    useEffect(async() => {
        const response = await Client.api.get(url)
        onDataFetched && onDataFetched(response)
        const {data, success} = response
        if(success){
            const list = data.map(v => {
               return {
                   ...v,
                   from: v.from ? v.from : v._by,
                   to: v.to ? v.to : v._uid,
               }
            })
            setList(list)
        }

    }, [])


    return <div className="show-notifications">
        {
            list.map(v => {
                return <NotificationTile key={v._id} {...v} />
            })
        }
    </div>
    
}

export default ShowNotifications