import Components from "../../../../components"
import CourseAsset from "../CourseAsset"

const CoursePart = ({label, name, value, data, onAddNew, onChange}) => {
    return <tr>
        <td>{label}</td>
        <td>
            <Components.input.Select.Field name={name} value={value} onChange={onChange}>
                {
                    !value &&
                    <Components.input.Select.Option value={""}>
                        Select One
                    </Components.input.Select.Option>
                }
                {
                    data.map(v => {
                        return <Components.input.Select.Option key={v._id} value={v._id}>
                            {v.title}
                        </Components.input.Select.Option>
                    })
                }
            </Components.input.Select.Field>
        </td>
        <td>
            <Components.buttons.FlatButton onClick={onAddNew}>New</Components.buttons.FlatButton>
        </td>
    </tr>
}



const OnlineCourseDetailsView = ({
        course, months, weeks, days, assets, 
        selectedMonth, selectedWeek, selectedDay, selectedAsset,
        onPublishCourse,
        onChangeMonth, onChangeWeek, onChangeDay, onChangeAsset,
        onAddMonth, onAddWeek, onAddDay, onAddAsset,
        onDeleteAsset,
        ...props
    }) => {
    return <div className="online-course">
        <h2>Course Details</h2>
        <div>Batch Title: {course.title}</div>
        <div>Symbol: {course.symbol}</div>
        <div>Description: {course.description}</div>
        <div>No of Months: {course.nMonths}</div>
        <Components.buttons.FlatButton onClick={onPublishCourse}>Publish</Components.buttons.FlatButton>
        <h3>Course Structure</h3>
        <div className="u-flex--row">
            <div style={{flex: 1}}>
                <table>
                    <thead></thead>
                    <tbody>
                        {/* {this.renderMonths()} */}
                        <CoursePart 
                            label="Months" 
                            name="selectedMonth" 
                            data={months} 
                            value={selectedMonth}
                            onChange={onChangeMonth} 
                            onAddNew={onAddMonth} 
                        />
                        {selectedMonth && 
                            <CoursePart 
                                label="Weeks" 
                                name="selectedWeek" 
                                data={weeks} 
                                value={selectedWeek}
                                onChange={onChangeWeek} 
                                onAddNew={onAddWeek} 
                            />
                        }
                        {selectedWeek &&
                            <CoursePart 
                                label="Days" 
                                name="selectedDay" 
                                data={days} 
                                value={selectedDay}
                                onChange={onChangeDay} 
                                onAddNew={onAddDay} 
                            />
                        }
                        {selectedDay && 
                            <CoursePart 
                                label="Assets" 
                                name="selectedAsset" 
                                data={assets} 
                                value={selectedAsset}
                                onChange={onChangeAsset} 
                                onAddNew={onAddAsset} 
                            />
                        }
                    </tbody>
                </table>
            </div>

            <div style={{flex: 3}}>
                {
                    selectedAsset &&
                    <CourseAsset key={selectedAsset} assetId={selectedAsset} onDeleteAsset={onDeleteAsset} />
                }
            </div>

        </div>
    </div>
}

export default OnlineCourseDetailsView