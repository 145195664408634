
import React from "react"
import Components from '../../../components'
import Client from "../../../services"


class StudentRegistration extends React.Component{
    state = {
        name: "",
        address: "",
        dob: "",
        father: "",
        mother: "",
        school: "",
        contact: "",
        email: ""
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const {name, school, father, mother, address, dob, contact, email} = this.state
        const payload = {name, address, dob, school, father, mother, contact}
        const {success, data, message} = await Client.api.post('/management/student/register', payload)
        if (success){
            await Client.alert.fire({
                icon: "success",
                title: "Success",
                body: message || "Student registration successfull.",
                confirmButton: true
            })
            this.props.history.replace('/management/student/list')

        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "New co ordintor registration failed.",
                confirmButton: true
            })
        }
    }

    render(){
        const {name, school, father, mother, address, dob, contact, email} = this.state
        return <div className="student-registration">
            <h2>Student Registration</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td><Components.input.TextInput name="name" placeholder="Name" value={name} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Name of the School</td>
                        <td><Components.input.TextInput name="school" placeholder="School Name" value={school} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Father Name</td>
                        <td><Components.input.TextInput name="father" placeholder="Father's name" value={father} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Mother Name</td>
                        <td><Components.input.TextInput name="mother" placeholder="Mother's name" value={mother} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Date of Birth</td>
                        <td><Components.input.TextInput type="date" name="dob" placeholder="Date of birth" value={dob} onChange={this.handleChange}/></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td><Components.input.TextInput name="address" placeholder="Address" value={address} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Phone No</td>
                        <td><Components.input.TextInput type="tel" name="contact" placeholder="Contact"  value={contact} onChange={this.handleChange} /></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><Components.input.TextInput type="email" name="email" placeholder="Email"  value={email} onChange={this.handleChange} /></td>
                    </tr>
                </tbody>
            </table>
            
            <Components.buttons.FlatButton onClick={this.handleSubmit}>Register</Components.buttons.FlatButton>
        </div>
    }
}

export default StudentRegistration