import React, { useState } from "react"
import Client from "../../services"
import LinearProgressBar from "../data/LinearProgresBar"

const FileUplaod = ({method="put", fieldName, url, onUploadSuccess, onUploadFailure, ...props}) => {
    const [success, setSuccess] = useState(null)
    const [message, setMessage] = useState("")
    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [filename, setFilename] = useState(null)
    const [file, setFile] = useState(null)

    const handleUpload = async() => {

        let fileData = new FormData()

        fileData.append(
            fieldName,
            file,
            filename
        )
        setProgress(0)
        setUploading(true)
        try{
            const response = await Client.api.put(url, fileData, {
                onUploadProgress: handleProgress,
            })
            onUploadSuccess && onUploadSuccess(response)
            setSuccess(true)
            setFile(null)
            setFilename("")
        }catch(err){
            onUploadFailure && onUploadFailure(err)
            setSuccess(false)
        }
        setUploading(false)
    }

    const handleProgress = (progress) => {
        const percentage = Math.floor(1000 * progress.loaded / progress.total) / 10
        setProgress(percentage)
    }

    const handleChange = (e) => {
        let {name, value, type, files} = e.target
        setFile(files[0])
        setFilename(value)
        setSuccess(null)
    }
    
    return <div className='fileupload'>
        <input name="" type="file" value={filename} accept='.mp4' onChange={handleChange}/>
        {
            uploading ? 
            <LinearProgressBar percentage={progress}>
                <div>{progress}% {progress > 99 && "Please wait! File is processing."}</div>
            </LinearProgressBar>
            :
            <button onClick={handleUpload}>Upload</button>
        }
    </div>

}

export default FileUplaod