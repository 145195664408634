import React from 'react'
import Components from '../../../components'
import Client from '../../../services'

const OnlineCourseSelector = ({onEnroll, ...props}) => {
    const [courses, setCourses] = React.useState([])
    const [value, setValue] = React.useState("")

    React.useEffect(async()=>{
        const {success, data} = await Client.api.get(`/admin/online-course/list`)
        if(success){
            setCourses(data)
        }
    }, [])

    const handleEnroll = async (e) => {
        e.preventDefault()
        if(!onEnroll) return;

        const success = await onEnroll(value)
        if(success) setValue("")
    }


    return <div className='u-flex--row'>
        <Components.input.Select.Field value={value} onChange={(e)=>setValue(e.target.value)}>
            <Components.input.Select.Option value={""}>Select course</Components.input.Select.Option>
            {
                courses.map(v => {
                    return <Components.input.Select.Option key={v._id} value={v._id}>{v.title}</Components.input.Select.Option>
                })
            }
        </Components.input.Select.Field>
        {
            value &&
            <Components.buttons.FlatButton onClick={handleEnroll}>Enroll</Components.buttons.FlatButton>
        }
    </div> 
}

export default OnlineCourseSelector