import React, { useEffect, useState } from 'react'
import Components from '../../../../components'
import Client from '../../../../services'
import CoOrdinatorList from './CoOrdinatorList'
import SchoolList from './SchoolList'

class StateView extends React.Component{
    
    state = {
        data: {data:{}}
    }
    // ===============================================================================================
    // Lifecycle
    // ===============================================================================================
    componentDidMount(){
        this.loadData()
    }
    // ===============================================================================================
    // Api Calls
    // ===============================================================================================
    loadData = async() => {
        const {id} = this.props.match.params
        
        const {success, data} = await Client.api.get(`/admin/entity/state/${id}`)
        if(success){
            this.setState({data})
        }else{
            
        }
    }
    
    
    // ===============================================================================================
    // UI Handlers
    // ===============================================================================================
   

    // ===============================================================================================
    // Rendering methods
    // ===============================================================================================

    
    // ===============================================================================================
    // Final Rendering
    // ===============================================================================================

    render(){
        const {data} = this.state
        return <div className="state-view">
            <h3>State Details</h3>

            <table>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>{data.name}</td>
                    </tr>
                    <tr>
                        <td>Entity Code</td>
                        <td>{data.entity}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{data.address}</td>
                    </tr>
                </tbody>
            </table>
            <SchoolList eid={this.props.match.params.id}/>
            <CoOrdinatorList eid={this.props.match.params.id}/>
        </div>
    }
}

export default StateView