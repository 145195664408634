import React from 'react'
import Components from '../../../components'
import Client from '../../../services'
import qs from 'query-string'

class ScheduleLecture extends React.Component{
    state = {
        bid: "",
        toUid: "", 
        date: "",
        time: "",
        duration: "", 
        subject: "", 
        topic: "", 
        description: "",
    }
    componentDidMount(){
        const queryString = this.props.location.search
        const {bid} = qs.parse(queryString)
        this.setState({bid})
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const { toUid, date, time, duration, subject, topic, description, bid } = this.state
        const payload = {
            uid: "6153835f3cb11685b424b82e",
            duration: Number(duration),
            startingTime: new Date(`${date}T${time}`),
            teacherId:toUid, 
            date, time, subject, topic, description, bid
        }

        // payload.offset = payload.startingTime.getTimezoneOffset()

        const {success, data, message} = await Client.api.post('/management/course/schedule/lecture', payload)
        if (success){
            this.props.history.replace('/management/schedule/lecture/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "Failed to create the batch.",
                confirmButton: true
            })
        }
    }

    render(){
        return <div className="schedule-lecture">
            <h3>Schedule Lecture</h3>
            <Components.forms.SimpleForm>
                <div>
                    <div className="u-flex--row">
                        <h4>Teacher</h4>
                        <Components.input.TextInput placeholder="Techer Id" name="toUid"  value={this.state.toUid}  onChange={this.handleChange}/>
                    </div>
                    <div className="schedule-lecture__teacher-list">
                        Teacher details
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>Date</td>
                            <td><Components.input.TextInput type="date" placeholder="Date" name="date" value={this.state.date} onChange={this.handleChange}/></td>
                            <td>Time</td>
                            <td><Components.input.TextInput type="time" placeholder="starting Time" name="time" value={this.state.time} onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td><Components.input.TextInput trailing="minute(s)" type="number" min={0} placeholder="Duration" name="duration" value={this.state.duration} onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Subject</td>
                            <td><Components.input.TextInput placeholder="Subject" name="subject" value={this.state.subject} onChange={this.handleChange}/></td>
                            <td>Topic</td>
                            <td><Components.input.TextInput placeholder="Topic" name="topic" value={this.state.topic} onChange={this.handleChange}/></td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td><Components.input.TextInput placeholder="Descriptionn" name="description" value={this.state.description} onChange={this.handleChange}/></td>
                        </tr>
                        <tr></tr>
                    </tbody>
                </table>
                
                <Components.buttons.FlatButton onClick={this.handleSubmit}>Submit</Components.buttons.FlatButton>
            </Components.forms.SimpleForm>
        </div>  
    }
}

export default ScheduleLecture