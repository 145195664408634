import accountant from './accountant'
import admin from './admin'
import common from './common'
import coordinator from './coordinator'
import stateCoordinator from './state-coordinator'
import course from './course'
import management from './management' 
import student from './student'
import teacher from './teacher'

const Containers = {
    accountant, admin, common, coordinator, course, management, student, teacher, stateCoordinator
}

export default Containers