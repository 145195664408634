import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components'
import Client from '../../../services';


const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "courseTitle": {label: "Course Name"},
    "title": {label: "Batch Name"},
    "description": {label: "Description"},
    "nStudents": {label: "No of Students"},
    "nTeachers": {label: "No of Teachers"},
    "actions": {label: "Actions"},
}

const CourseList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        
        const {success, data} = await Client.api.get(`/management/course/batch/list?uid=6153835f3cb11685b424b82e`)
        if(success){
            const newList = data.map((v,i) => {
                const {_cid:course, ...batch} = v
                return {
                    ...batch,
                    course,
                    courseTitle: course.title,
                    index: i, 
                    actions: <button onClick={(e)=>props.history.push(`/management/course-batch/${v._id}`)} >View</button>}
            })
            console.log(newList)
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="student-list">    
        <h2>Batch List</h2>
        <ul>
            <li><Link to="/management/course/list">Course List</Link></li>
        </ul> 
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "courseTitle", "title", "description", "nStudents", "nTeachers",  "actions"]}
            data={list}
        />
    </div>
}

export default CourseList