import datetime from 'date-and-time'
const { useEffect, useState } = require("react")

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


const Calendar = ({}) => {

    const today = new Date()

    const [dates, setDates] = useState([])
    const [month, setMonth] = useState(0)
    const [year, setYear] = useState(0)
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date(today.getFullYear(), today.getMonth(), 1))
    
    useEffect(()=>{
        const currentMoonth = datetime.addMonths(datetime.addYears(today, year), month)
        setFirstDayOfMonth(new Date(currentMoonth.getFullYear(), currentMoonth.getMonth(), 1))
        
    }, [month, year])

    useEffect(()=>{
        const firstDayInFrame = datetime.addDays(firstDayOfMonth, -firstDayOfMonth.getDay())
        const newDates = []
        for(let i=0; i<42; i++){ // 7days * 6weeks = 42 Days
            const d = datetime.addDays(firstDayInFrame, i)
            newDates[i] = d
        } 
        setDates(newDates)
    }, [firstDayOfMonth])

    console.log(firstDayOfMonth)
    const [color, setColor] = useState("calender--blank")

    return <div className="calendar">
        
        <div className="calendar__header u-flex--row">
            <div className="calendar__handle--year" onClick={()=>setYear(year-1)}>{"<<"}</div>
            <div className="calendar__handle--month" onClick={()=>setMonth(month-1)}>{"<"}</div>
            <div className="u-flex--1"></div>
            <div className="calendar__title">{months[firstDayOfMonth.getMonth()]} - {firstDayOfMonth.getFullYear()}</div>
            <div className="u-flex--1"></div>
            <div className="calendar__handle--month" onClick={()=>setMonth(month+1)}>{">"}</div>
            <div className="calendar__handle--year" onClick={()=>setYear(year+1)}>{">>"}</div>
        </div>

        <div className="calendar__month">
            {["Sun", "Mon", "Tue", "Wed", "Thus", "Fri", "Sat"].map(s => <div key={s} className="calendar__day calendar__day--label">{s}</div> )}
            {
                dates.map((day, i) => {
                    let className = "calendar__day"
                    if(datetime.format(day, "YYYYMMDD").localeCompare(datetime.format(today, "YYYYMMDD")) === 0) className = `${className} calendar__day--today`
                    if(datetime.format(day, "YYYYMM").localeCompare(datetime.format(firstDayOfMonth, "YYYYMM"))) className = `${className} calendar__day--outbound`
                    // if(datetime.format() === date.toDateString()) className = `${className} calendar__day--today`
                    return <div key={i} className={className}>{day.toDateString()}</div> 
                })
            }
        </div>
    </div>
}

const CalendarEvent = () => {

}

export default Calendar