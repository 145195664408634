import List from './StudentList'
import Registration from './StudentRegistration'
import Enroll from './EnrollStudentCourse'

const exportObj = {
    List,
    Registration,
    Enroll
}

export default exportObj