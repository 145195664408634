import { useEffect, useState } from "react"
import Components from "../../../components"
import Client from "../../../services"


const headerMap = {
    "index": {label: "Index",},
    "_id": {label: "Id",},
    "_cid": {label: "Course Id",},
    "level": {label: "Level",},
    "term": {label: "Term",},
    "amount": {label: "Amount",},
    "dueDate": {label: "Due on",},
    "done": {label: "Payment Done",},
    "_pid": {label: "Payment Id",},
    "options": {label: "options"},
}


 


const FeesCollection = ({history}) => {
    const [list, setList] = useState([])

    useEffect(async () => {
        const {success, data} = await Client.api.get('/student/payment/fees/list')
        if(success){
            const list = data.map((fee, i)=>{
                const dueDate = (new Date(fee.dueDate))
                return {
                    index: i,
                    ...fee,
                    done: fee.done ? "Done" : "-",
                    dueDate: `${dueDate.getDate()}/${dueDate.getMonth()+1}/${dueDate.getFullYear()}`,
                    dueDate: dueDate.toDateString(),
                    // options: fee.done? 
                    //     <button>View details</button> 
                    //     : <button onClick={(e)=>handleMakePayment(fee, e)} >Make payment</button>
                }
            })
            setList(list)
        }else{
            setList([])
        }
    }, [])

 


    return <div className="fees-collection">
        {/* <Components.input.Search label="Search student name" initialData={[]} 
            // onValueChange={(data, searchText)=> {
            //         const result = data.filter(v => v.value.indexOf(searchText) > -1)
            //         return result
            //     }
            // }
            render={(v) => {
                return <div style={{color: "red"}}>{v.value}</div>
            }}
        /> */}
        <h2>Fees Payment Info</h2>
        <div>
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "_id", "_cid", "level", "term", "amount", "dueDate", "done", "_pid"]}
                data={list}
            />
        </div>
        
    </div>
}

export default FeesCollection