import axios from 'axios'
import React, { useEffect, useState } from 'react'

import Components from '../../../components'
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "_id": {label: "Enrollment Id",},
    "username": {label: "Student Id",},
    "name": {label: "Name",},
}

class CourseAsset extends React.Component{
    state = {
        asset: {},
        filename: "",
        file: null,
        progress: 0,
        uploading: false,
        randomNumber: 0,
        newQuestion: "",
        fileNewQuestions: null,
        filenameNewQuestions: ""
    }
    componentDidMount(){
        this.loadDetails()
    }

    // ==============================================================================================
    // Api Calls
    // ==============================================================================================

    deleteAsset = async() => {
        try{
            const id = this.props.assetId
            const {success, data, message} = await Client.api.delete(`/admin/online-course/month/week/day/asset/${id}`)
            if(success){
                console.log(data)
                this.props.onDeleteAsset && this.props.onDeleteAsset(id)
            }else{
                Client.alert.fire({
                    icon: "failure",
                    title: "Loading failed",
                    body: message,
                    confirmButton: true,
                })
            }

        }catch(err){
            Client.alert.fire({
                icon: "error",
                title: "Something went wrong!",
                body: err,
                confirmButton: true,
            })
        }
    }

    loadDetails = async () => {
        const id = this.props.assetId
        const {success, data, message} = await Client.api.get(`/admin/online-course/month/week/day/asset/${id}`)
        if(success){
            this.setState({asset: data})
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Loading failed",
                body: message,
                confirmButton: true,
            })
        }
    }

    pushNewQuestion = async() => {
        const id = this.props.assetId
        const payload = {
            question:  this.state.newQuestion.trim()
        }
        const {success, data, message} = await Client.api.put(`/admin/online-course/month/week/day/asset/${id}/pushNewQuestion`, payload)
        if(success){
            this.setState({
                asset: data,
                newQuestion: ""
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Cannot add new question.",
                body: message,
                confirmButton: true,
            })
        }

        return success
    }

    pushQuestions = async(questions) => {
        console.log(questions) 
        const id = this.props.assetId
        const payload = {
            questions
        }
        const {success, data, message} = await Client.api.put(`/admin/online-course/month/week/day/asset/${id}/questions`, payload)
        if(success){
            this.setState({
                asset: data,
                newQuestion: ""
            })
        }else{
            Client.alert.fire({
                icon: "failure",
                title: "Cannot add new question.",
                body: message,
                confirmButton: true,
            })
        }

        return success
    }

    uploadFile = async(fileData, onProgress) => {
        const id = this.props.assetId
        const {data} = await Client.api.put(`/admin/online-course/month/week/day/asset/${id}/video`, fileData, {
            onUploadProgress: onProgress,
            timeout: 20000 * 1000,
        })

        this.setState({
            asset: data
        })

        let randomNumber = Math.floor(Math.random() * 100000000)
        while (randomNumber === this.state.randomNumber) randomNumber = Math.floor(Math.random() * 100000000)

        this.setState({
            uploading: false,
            randomNumber
        })
    }


    uploadFileSuccess = async({data}) => {
        
        this.setState({
            asset: data
        })

        let randomNumber = Math.floor(Math.random() * 100000000)
        while (randomNumber === this.state.randomNumber) randomNumber = Math.floor(Math.random() * 100000000)

        this.setState({
            uploading: false,
            randomNumber
        })
    }



    // ====================================================================================
    // UI Handlers
    // ====================================================================================
    
    handleChange = (e) => {
        let {name, value, type} = e.target
        this.setState({
            [name]: value
        })
    }

    handleChangeFile = (e) => {
        let {name, value, type, files} = e.target
        // const stateChanges = {name: {...this.state.name}, file: {...this.state.file}}
        // stateChanges.name[name] = value
        // stateChanges.file[name] = e.target.files[0]
        const payload = {}
        payload[`file${name}`] = files[0]
        payload[`filename${name}`] = value
        // this.setState({filename: value, file: e.target.files[0]})
        this.setState(payload)
    }

    handleDeleteAsset = (e) => {
        e.preventDefault()
        this.deleteAsset()
    }

    handleKeyPress = (e)=> {
        // e.preventDefault()
        const {key, target} = e
        const {name, value, type} = target
        switch(key){
            case "Enter": {
                switch(name){
                    case "newQuestion": this.pushNewQuestion()
                }
                break;
            }
        }
    }

    
    handleUploadFile = async (name) => {
        
        let fileData = new FormData()

        fileData.append(
            "asset-video",
            this.state.file,
            this.state.name
        )
        
        const onProgress = (progress) => {
            const percentage = Math.floor(1000 * progress.loaded / progress.total) / 10

            this.setState({
                progress: percentage,
                uploading: true,
            })
        }
        this.uploadFile(fileData, onProgress)
    }

    handleUploadQuestionsFromFile = () =>{
      
        let fileReader = new FileReader();
        const pushQuestions = this.pushQuestions
        fileReader.onload = function(fileLoadedEvent){
            let textFromFileLoaded = fileLoadedEvent.target.result;
            const lines = textFromFileLoaded.replaceAll(",", "\n\r").split("\n").join("").split("\r")
            const questions = lines.filter(v => !!v).map(v => v.trim())
            pushQuestions(questions)
            
        };
    
        fileReader.readAsText(this.state.fileNewQuestions, "UTF-8");
    }

    
    handleShowAssetDetails  = (id, e) => {
        e.preventDefault()
        
        this.setState({selectedAsset: id})
    }

    // ===================================================================================================
    // Render helper
    // ===================================================================================================
    renderVideo = () => {
        const {asset, uploading, progress, randomNumber} = this.state
        const src = asset.urls["original"] ? `${asset.urls["original"]}?random=${randomNumber}` : ``
        
        return <div className="online-course-asset__video">
            <div className='online-course-asset__video-input'>
                {/* <input name="" type="file" accept='.mp4' onChange={this.handleChangeFile}/>
                {uploading ? 
                    <div>{progress}</div>
                    :
                    <button onClick={this.handleUploadFile}>Upload</button>
                } */}
                <Components.input.FileUpload fieldName="asset-video" method='put' url={`/admin/online-course/month/week/day/asset/${this.props.assetId}/video`} onUploadSuccess={this.uploadFileSuccess} />
            </div>
            <div className='online-course-asset__video-output'>
                {
                    src &&
                    <video height="100%"  controls src={src}/>
                }

            </div>
        </div>
    }


    renderExerciseMath = () => {
        const {asset, newQuestion, filenameNewQuestions} = this.state

        return <div className="online-course-asset__math">
            <h3>Basic Info <button onClick={this.handleDeleteAsset}>Delete</button> </h3> 
            <table>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td>{asset.type}</td>
                    </tr>
                    <tr>
                        <td>Play Type</td>
                        <td>{asset.playType}</td>
                    </tr>
                </tbody>
            </table>
            <div className='online-course-asset__math-settings'>
                <h3>Settings</h3>
                <table>
                    <tbody>
                        {
                            Object.keys(asset.settings).map(key => {
                                return <tr key={key}>
                                    <td>{key}</td>
                                    <td>{asset.settings[key]}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='online-course-asset__math-questions'>
                <h3>Questions</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <input name="NewQuestions" value={filenameNewQuestions}  type="file" accept='.txt' onChange={this.handleChangeFile}/>
                                <button onClick={this.handleUploadQuestionsFromFile}>Upload</button>
                                
                            </td>
                        </tr>
                        {
                            asset.questions.map((value, index) => {
                                return <tr key={index}>
                                    <td>{value}</td>
                                </tr>
                            })
                        }
                        <tr>
                            <td>
                                <Components.input.TextInput value={newQuestion} name="newQuestion" 
                                    onChange={this.handleChange} 
                                    onKeyPress={this.handleKeyPress}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    

    // ====================================================================================================
    // Final rendering
    // ====================================================================================================

    render(){
        const headers = ["index", "_id", "username", "name"]
        const {asset} = this.state
        let assetView = null
        switch(asset.type){
            case "VIDEO": {assetView = this.renderVideo();break;}
            case "ABACUS": {assetView = this.renderExerciseMath();break;}
            case "ABACUSTONUMBER": {assetView = this.renderExerciseMath();break;}
            case "FINGERS": {assetView = this.renderExerciseMath();break;}
            case "NUMBERTOABACUS": {assetView = this.renderExerciseMath();break;}
            case "MENTALABACUS": {assetView = this.renderExerciseMath();break;}
        }
        return <div className="online-course-asset">
            <h3>Asset Details</h3>
            {assetView}
        </div>
    }
}

export default CourseAsset