import React, { useEffect, useState } from 'react';
import Components from '../../components'

const CourseList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(()=>{
        const template = {id: 0, title: "Course name", course: "Course description", created: (new Date()).toDateString(), }
        const newList = []
        for(let i=0; i < 100; i++){
            newList.push({...template, id: i+1})
        }
        setList( _ => newList)
    },[])




    return <div className="student-list">     
        <Components.data.Table data={list}/>
    </div>
}

export default CourseList