import React from "react"
import Components from "../../components"
import Client from "../../services"

class  MakePayment extends React.Component{
    state = {
        student: {},
        enrollment: {},
        course: {},
        fee: {},
        payment: {},
        amount: 0,
    }
    async componentDidMount(){
        const {type, id} = this.props.match.params
        
        const {success, data} = await Client.api.get(`/accountant/${type}/fee/${id}`)

        if(success){
            const {_pid:payment, _enrollment: {_uid: student, ...enrollment}, _cid: course, ...fee} = data
            this.setState({student, enrollment, course, fee, payment})
        }
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        this.setState({
            [name]: value
        })
    }

    handlePayment = async (e) => {
        e.preventDefault()
        const {course, enrollment, fee, student, amount} = this.state

        const { isConfirmed, value} = await Client.alert.fire({
            icon: "warning",
            title: "Warning",
            body: `Payment of Rs ${amount} out of Rs ${fee.amount} with reference ${fee._id}. Total discount is ${fee.amount - amount}.`,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Confirm",
            preConfirm: async() => {
                const {type} = this.props.match.params
                const payload = {
                    uid: "61580b3fd7b85a5f25d6787e", 
                    courseFeeId: fee._id, 
                    amount: Number(amount),
                    reference: fee._id
                }
                return await Client.api.post(`/accountant/${type}/fee/payment`, payload)
            }
        })
        if(isConfirmed){
            const {success, data: {payment}, message} = value
            if(success){
                Client.alert.fire({
                    icon: "success",
                    title: payment._id,
                    body: `Payment successful. Payment id ${payment._id} with reference ${payment.reference}`,
                    confirmButton: true,
                    preConfirm: () => {
                        this.props.history.goBack()
                    }
                })
            }else{
                Client.alert.fire({
                    icon: "failed",
                    title: payment._id,
                    body: `Payment failed with reference ${fee._id}`,
                    confirmButton: true
                })
            }
        }
    }
    render(){
        const {course, enrollment, fee, student, payment, amount} = this.state
        const today = (new Date())
        const dueDate = (new Date(fee.dueDate))
        const ammountDiff = ( fee.done ? payment.paidAmount : Number(amount))  - fee.amount


        return <div className="course-fee-payment">
            <center><h2>Course Fee Payment</h2></center>
            <div className="u-flex--row">
                <div className="u-flex--1">
                    <h3>Student Details</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Registration Id</td> <td>{student._id}</td>
                            </tr>
                            <tr>
                                <td>Student Id</td> <td>{student.username}</td>
                            </tr>
                            <tr>
                                <td>Student Name</td> <td>{student.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="u-flex--1">
                    <h3>Enrollment Details</h3>
                    <table>
                        <tbody>

                            <tr>
                                <td>Enrollment Id</td> <td>{enrollment._id}</td>
                            </tr>
                            <tr>
                                <td>Course Id</td> <td>{course._id}</td>
                            </tr>
                            <tr>
                                <td>Course Name</td> <td>{course.title}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h3>Fees Details</h3>

                <div className="u-flex--row">
                    <div className="u-flex--1">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Date</td> <td>{today.toDateString()}</td>
                                </tr>
                                <tr>
                                    <td>Course Fee id</td> <td>{fee._id}</td>
                                </tr>
                                <tr>
                                    <td>Reference: </td> <td>{fee._id}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="u-flex--1">
                        <table>
                            <tbody>  
                                <tr>
                                    <td>Due on</td> <td>{dueDate.toDateString()}</td>
                                </tr>
                                <tr>
                                    <td>Course</td><td>{course.title}</td>
                                </tr>
                                <tr>
                                    <td>Level</td><td>{fee.level}</td>
                                </tr>
                                <tr>
                                    <td>Term</td><td>{fee.term}</td>
                                </tr>
                                <tr>
                                    <td>Amount: </td> <td>Rs {fee.amount} /-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>


            <div className="course-fee-payment__details">
                <h3>Payment Details</h3>
                <table className="course-fee-payment__fee-structure">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{minWidth: "25vw"}}>Particular</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Tuition Fee</td>
                            <td>{fee.amount}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Late Fee</td>
                            <td>{ammountDiff < 0 ? 0 : ammountDiff}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Discount</td>
                            <td>({ammountDiff < 0 ? -ammountDiff : 0})</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Total</td>
                            <td>{fee.amount + ammountDiff}</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Amount Paid</td>
                            <td>
                                {
                                    fee.done ? payment.paidAmount :
                                    <Components.input.TextInput 
                                        type="number" name="amount" 
                                        value={this.state.amount} 
                                        onChange={this.handleChange} 
                                    />
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>

                {
                    !fee.done && 
                    <div className="course-fee-payment__actions">
                        <Components.buttons.FlatButton >Cancel</Components.buttons.FlatButton>
                        <Components.buttons.FlatButton onClick={this.handlePayment}>Initiate Payment</Components.buttons.FlatButton>
                    </div>
                }
            </div>
        </div>
    }
}


export default MakePayment