import React from 'react';
import Components from '../../../components';
import Client from '../../../services';

const userTypes = {
    [Client.constants.user.kind.USER_TYPE_STATE_COORDINATOR]: "State Co-ordinator",
    [Client.constants.user.kind.USER_TYPE_CENTER_COORDINATOR]: "School Co-ordinator",
}

class NewEntityForm extends React.Component{
    state = {
        kind: "",
        entity: "",
        username: "",
        password: "",
        name: "",
        address: "",
        email: "",
        contact: ""
    }
    

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }


    handleSubmit = async (e) => {
        e.preventDefault()
        const {kind, name, entity, address, username, password, email, contact} = this.state
        const urls = {
            [Client.constants.user.kind.USER_TYPE_STATE_COORDINATOR]: '/admin/user/manager',
            [Client.constants.user.kind.USER_TYPE_CENTER_COORDINATOR]: '/admin/user/coordinator',
        }
        const url = urls[kind]
        if(!kind){
            Client.alert.fire({
                icon: "Error",
                body: "Select the kind of user."
            })
            return
        }
        
        const payload = {name, address, entity, username, password, email, contact}
        const {success, message} = await Client.api.post(url, payload)

        if (success){
            await Client.alert.fire({
                title: "Success",
                body: message || "New co ordintor succesfully registered.",
                confirmButton: true,
            })
            this.props.history.replace('/admin/user/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "New co ordintor registration failed.",
                confirmButton: true
            })
        }
    }

    renderEntity = () => {
        const {entity, kind} = this.state

        switch(kind){
            case Client.constants.user.kind.USER_TYPE_STATE_COORDINATOR: {
                return <tr>
                    <td>State Id</td>
                    <td><Components.input.TextInput name="entity" value={entity} onChange={this.handleChange} /></td>
                </tr> 
            }
            case Client.constants.user.kind.USER_TYPE_CENTER_COORDINATOR: {
                return <tr>
                    <td>School Id</td>
                    <td><Components.input.TextInput name="entity" value={entity} onChange={this.handleChange} /></td>
                </tr> 
            }
        }
    }

    renderUserbasicDetails = () => {
        const {name, address, email, contact} = this.state

        return <>
            <tr>
                <td>Name</td>
                <td><Components.input.TextInput name="name" placeholder="Name" value={name} onChange={this.handleChange} /></td>
            </tr>
            <tr>
                <td>Address</td>
                <td><Components.input.TextArea name="address" placeholder="Address" value={address} onChange={this.handleChange} /></td>
            </tr>
            <tr>
                <td>Email</td>
                <td><Components.input.TextInput name="email" type="email" placeholder="Email address" value={email} onChange={this.handleChange} /></td>
            </tr>
            <tr>
                <td>Phone no</td>
                <td><Components.input.TextInput name="contact" type="number" placeholder="Phone no" value={contact} onChange={this.handleChange} /></td>
            </tr>
        </>
    }

    renderLoginDetails = () => {
        const { username, password} = this.state

        return <tr>
            <td>Username</td>
            <td><Components.input.TextInput name="username" placeholder="Username" value={username} onChange={this.handleChange} /></td>
            <td>Password</td>
            <td><Components.input.TextInput name="password" type="password" value={password} onChange={this.handleChange} /></td>
        </tr>
    }

    render(){
        const {kind} = this.state
        return <div className="new-coordinator">
            <h2>Create new user</h2>
            <Components.forms.SimpleForm>
                <table>
                    <tbody>
                        <tr>
                            <td>Kind of User</td>
                            <td>
                                <Components.input.Select.Field name="kind" value={kind} onChange={this.handleChange} >
                                    <Components.input.Select.Option value="">Select One</Components.input.Select.Option>
                                    {
                                        Object.keys(userTypes).map(key => {
                                            return <Components.input.Select.Option key={key} value={key}>{userTypes[key]}</Components.input.Select.Option>
                                        })
                                    }
                                </Components.input.Select.Field>
                            </td>
                        </tr>
                        {this.renderEntity()}
                        {kind && this.renderUserbasicDetails()}
                        {/* {kind && this.renderLoginDetails()} */}
                    </tbody>
                </table>
                {kind && <Components.buttons.FlatButton onClick={this.handleSubmit}> Submit</Components.buttons.FlatButton>}
            </Components.forms.SimpleForm>
        </div>
    }
}

export default NewEntityForm