import React from 'react'
import { Link } from 'react-router-dom'
import NotificationTile from '../../../pageComponents/Notification/NotificationTile'
import ShowNotifications from '../../../pageComponents/Notification/ShowNotifications'
import Client from '../../../services'
import SendNotification from './SendNotification'


class MyNotifications extends React.Component{
        
    


    render(){
        return <div className="notification-sent">
            <h3>Notifications</h3>
            <ShowNotifications url="/common/notification" />
            <ShowNotifications url="/common/notification/all" />
            <ShowNotifications url="/common/notification/management" />
            <ShowNotifications url="/common/notification/teacher" />
            <ShowNotifications url="/common/notification/student" />
        </div>
    }
}

export default MyNotifications