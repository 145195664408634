import { Link } from "react-router-dom"
import Components from "../../components"

const Dashboard = () => {
    return <div className="dashboard of-management">
        <ul>
            <Components.data.Calendar/>

        </ul>
    </div>
}

export default Dashboard