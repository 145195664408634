import React from 'react'
import hocs from '../../../hocs'
import Client from '../../../services'
import Attendance from './Attendance'

class LectureDetails extends React.Component{

    state = {
        schedule: {
            _by: {}
        },
        school: {},
        scheduledBy: {},
        lecture:{},
        course: {},
        batch: {}
    }
    async componentDidMount(){
        const {id} =  this.props.match.params
        const {success,data} = await Client.api.get(`/teacher/schedule/lecture/${id}?uid=615df1b7e287a0c0098f5193`)
        if(success){
            const {data:{_sid: school, _cid: course, _bid: batch, ...lecture}, _by:scheduledBy, ...schedule} = data
            this.setState({schedule, lecture, school, course, batch, scheduledBy})
        }
    
    }
    // ==============================================================================================================
    // ApiCalls
    // ==============================================================================================================
    toggleMarkPresent = async (attendee, e, cb) => {
        const {id:scheduleId} =  this.props.match.params
        const payload = {
            id: this.state.lecture.attendance,
            scheduleId,
            attendee,
            isPresent: e.target.checked
        }
        const {success, data:checkInTime} = await Client.api.put(`/teacher/schedule/lecture/mark/attendance`, payload)
        cb(checkInTime)
    }
    // ==============================================================================================================
    // Final rendering
    // ==============================================================================================================
    render(){
        const {id:scheduleId} =  this.props.match.params
        const {schedule, lecture, school, scheduledBy, course, batch} = this.state
        return <div className="lecture-details">
            <h3>Scheduled Lecture</h3>
            <div>Scheduled By: {scheduledBy.name}</div>
            <div>Subject: {lecture.subject}</div>
            <div>Topic: {lecture.topic}</div>
            <div>Description: {lecture.description}</div>
            <div>Date {"&"} Time: {schedule.scheduledAt}</div>
            <div>Duration: {schedule.duration}</div>
            <div>School Name: {school.name}</div>
            <div>School Address: {school.address}</div>
            <div>Course Name: {course.title}</div>
            <div>Batch Name: {batch.title}</div>


            <Attendance scheduleId={scheduleId} onMarkPresent={this.toggleMarkPresent}/>
        </div>
    }
}

export default LectureDetails