import React from "react"
import LineChart from "../charts/Line";

const LineWidget = ({ 
    data=[123,32,123,545,334],
    height=80,
    width=150,
    color="red",
}) => {
    const x = data.map((_, i) => i) 
    let className = "minichart line-widget"
    if(color){
        className = `${className} minichart--color-${color}`
    }
    return <div className={className}>
        <div className="minichart__title">Title</div>
        <LineChart
            height={height}
            width={width}
            x={x}
            y={[{
                data: data,
                backgroundColor: `rgba(0, 0, 0, 0.1)`,
                // backgroundColor: `rgba(0,0,0,0.2)`,
                fill: true,
                tension: 0.2,
            }]}
            showAxis={{x: false, y: false}}
            interaction={false}
            pointRadius={0}
        />
    </div>
}

export default LineWidget