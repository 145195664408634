/**
 * MultiChoice is for multiple choice input
 */
const MultiChoice = ({leading, trailing, header, footer, radio, checked, ...props}) => {

    return <div className="input has-text">
        <div className="input has-header">{header}</div>
            <div className="u-flex--row">
                <div className="input has-leading">{leading}</div>
                <input type={radio? "radio" : "checkbox"} {...props} value={checked} checked={checked} />
                <div className="input has-trailing">{trailing}</div>
            </div>
        <div className="input has-footer">{footer}</div>
        
    </div>
}

export default MultiChoice