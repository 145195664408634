import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "name": {label: "Name",},
    "kind": {label: "User Type",},
    "createdAt": {label: "Created At",},
    "lastSignedIn": {label: "Last Signed In",},
    "username": {label: "Username",},
}



const StudentList = ({...props}) => {
    
    var [list, setList] = useState([])
    var [orderBy, setOrderBy] = useState({})
    
    // Functions
    const getData = async () => {
        const {success, data} = await Client.api.get('/admin/user/list')
        if(success){
            const newList = data.map((user, i) => {
                return {
                    index: i+1,
                    ...user,
                    createdAt: (new Date(user.createdAt)).toLocaleString(),
                }
            })
            setList(newList)
        }else{
            setList([])
        }
    }
    
    // Component Did mount
    useEffect(getData,[])
    
    // Listens to changes on 
    useEffect(getData,[orderBy])



    const headers = ["index", "name", "kind", "createdAt",  "lastSignedIn", "username"]


    return <div className="user-list">    
        <ul>
                <li><Link to="/admin/user/new">Create new user</Link></li>
                {/* <li><Link to="/admin/entity/school/register">Register New School</Link></li> */}
            </ul>
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default StudentList