import React from 'react'
import { Fragment } from 'react'
import { withRouter } from 'react-router'
import Components from '../../components'
import Client from '../../services'
import LoginForm from './LoginForm'
import LoginScreen from './LoginScreen'

class Navigation extends React.Component{

    state = {
        showLoginScreen: false,
        loggedIn: Client.session.loggedIn,
        user: {},
    }


    // ========================================================================================================================
    // Lifecycle Methods
    // ========================================================================================================================
    componentDidMount(){
        this.setState({
            user: Client.session.loggedIn? Client.session.user : {}
        })
    }
    // ========================================================================================================================
    // Api Calls
    // ========================================================================================================================
    
    // ========================================================================================================================
    // UI Handlers
    // ========================================================================================================================
    handleChange = (e) => {
        const {name, type} = e.target
        let {value} = e.target
        e.preventDefault()
        this.setState({
            [name]: value
        })
    }


    handleLoginCallback = (loggedIn) =>  {
        this.setState({
            loggedIn, 
            showLoginScreen: !loggedIn, 
            user: loggedIn? Client.session.user : {}
        })
    }
    
    handleLogout = async (e) => {
        e.preventDefault()
        await Client.session.logout()
        this.props.history.replace('/')
        this.setState({
            loggedIn: Client.session.loggedIn
        })
    }

    handleToggleLoginScreen = (e) => {
        e.preventDefault()
        this.setState({showLoginScreen: !this.state.showLoginScreen})
    }

    
    // ========================================================================================================================
    // Renderer
    // ========================================================================================================================

    renderProfileOptions = () => {
        const {user } = this.state

        return <>
            <div>{user.name}</div>
            {/* <div> */}
                <Components.buttons.FlatButton onClick={this.handleLogout}>Logout</Components.buttons.FlatButton>
            {/* </div> */}
        </>
    }

    getNavItems = () => {
        let left = null, center = null, right = null
        const userType = Client.session.userType;

        if(this.state.loggedIn){
            left=<>
                <Components.nav.Item to="/dashboard">Dashboard</Components.nav.Item>
            </>
            right=this.renderProfileOptions()
            center=<>
                <div>{userType}</div>
            </>
        }else{
            left=<>
                <Components.nav.Item to="/">Home</Components.nav.Item>
            </>
            right=<>
                <Components.buttons.FlatButton onClick={this.handleToggleLoginScreen}>Login</Components.buttons.FlatButton>
            </>

        }

        switch(userType){
            case Client.session.typeManagement:{
                break;
            }
            case Client.session.typeTeacher:{
                break;
            }
        }

        
        return {left, 
            right, 
            center
        }
    }

    

    // ========================================================================================================================
    // Final rendering
    // ========================================================================================================================

    render(){
        const {showLoginScreen, loggedIn} = this.state
        const {authorizedApp, publicApp} = this.props
        const navitems = this.getNavItems()
        return <div className="navigation">
            
            {/* If we want to show the login button on navbar, then comment out the below price of code */}
            {/* 
                <Components.nav.Navbar
                    logo="3Q Abacus ERP"
                    {...navitems}
                />
                <LoginScreen key={showLoginScreen} show={showLoginScreen} loginCallback={this.handleLoginCallback} onClose={this.handleToggleLoginScreen}/>
            */}
            
            {/* <LoginScreen key={showLoginScreen} show={!loggedIn} loginCallback={this.handleLoginCallback} onClose={this.handleToggleLoginScreen}/> */}
            
            {
                loggedIn && <Components.nav.Navbar
                    logo="3Q Abacus ERP"
                    {...navitems}
                />
            }
            { loggedIn? authorizedApp : <LoginForm  onComplete={this.handleLoginCallback}/> }
        </div>
    }
}

export default withRouter(Navigation)