import React from 'react'

const LinearProgressBar = ({min=0, max=100, value=36, percentage=36, ...props}) => {
    if(!percentage){
        percentage = 100 * value/(max-min)
    }
    
    return <div style={{position: "relative"}}>
        <div >{props.children}</div>
        <div style={{zIndex: -1, width: `${percentage}%`, background: "green", position: "absolute", top: 0, left: 0, bottom: 0}}></div>
    </div>
}

export default LinearProgressBar