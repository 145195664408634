export const HOME_URL = "/tradefinance";

export const notfication = {
    types: {
        ALL: "ALL",
        INDIVISUAL: "INDIVISUAL",
        SCHOOL: "SCHOOL",
        MANAGEMENT: "MANAGEMENT",
        STUDENT: "STUDENT",
    }
}

export const entity = {
    kind: {
        ENTITY_STATE: "ENTITY_STATE",
        ENTITY_SCHOOL: "ENTITY_SCHOOL",
    }
}

export const user = {
    kind: {
        USER_TYPE_ACCOUNTANT: "USER_ACCOUNTANT",
        USER_TYPE_ADMIN: "USER_ADMIN",
        USER_TYPE_CLIENT_ADMIN: "USER_CLIENT_ADMIN",
        USER_TYPE_CENTER_COORDINATOR: "USER_CENTER_COORDINATOR",
        USER_TYPE_ENTITY_ADMIN: "USER_ENTITY_ADMIN",
        USER_TYPE_STATE_COORDINATOR: "USER_STATE_COORDINATOR", // USER OF ENTITY_STATE ADMINISTRATION RELATED.
        USER_TYPE_MANAGEMENT: "USER_MANAGEMENT", // USER of SCHOOL MANAGEMENT DEPARTMENT.
        USER_TYPE_STUDENT: "USER_STUDENT", 
        USER_TYPE_TEACHER: "USER_TEACHER",   
    }
}