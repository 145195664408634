import CourseBatch from './CourseBatch'
import Dashboard from './Dashboard'
import Schedule from './Schedule'
import TeacherList from './TeacherList'

const exportObj = {
    CourseBatch,
    Dashboard,
    Schedule,
    TeacherList
}

export default exportObj