import api from './api';
import log from './clientLog';
import store from './store';
import Swal from './swal';
import {ClientSession} from './session';
import UIContainers from './uiContainer';
// import actions from '../store/actions';
import utility from './utility';
import * as constants from './constants'

const Client = {
    constants,
    /**
     * This property will allow to send API request
     */
    api,
    /**
     * This property exposed decorated console log helpers.
     */
    log,
    /**
     * This is the redux store which is globally used in this application.
     */
    store,
    /**
     * The actions can be taken with redux store and defined in this application. 
     * Those functions are imported from /store/actions/index.js
     */
    // actions: actions,
    /**
     * This property will allow to trigger a beutiful alert that supports react component to be rendered
     * @deprecated use Client.alert instead.
     */
    modal: Swal,
    /**
     * This property will allow to trigger a beutiful alert that supports react component to be rendered
     */
     alert: Swal,
    /**
     * This property stores the session informaiton.
     */
    session: ClientSession,
    /**
     * Experimental feature. 
     * This property can be used to access the registered component in realtime basis
     */
    ui:{
        containers: UIContainers
    },
    /**
     * This property exposes many helpful functions
     */
    utility,
}

export default Client