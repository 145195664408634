import { useEffect } from "react"
import { useState } from "react"
import Components from "../../../../components"
import Client from "../../../../services"
import { ClientSession } from "../../../../services/session"

const SearchCoOridinator = ({ onClickItem }) => {
    const [searching, setSearching] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [value, setValue] = useState("")
    const [user, setUser] = useState(null)
    let timeoutId = -1
    const searchUser = async (e, callback) => {
        const { value } = e.target
        if (showResult === false) setShowResult(true)
        clearTimeout(timeoutId)
        // setSid(value)
        if (value.length < 3) {
            callback([])
            // setShowList(false)
        }
        timeoutId = setTimeout(async () => {
            const userKind = Client.constants.user.kind.USER_TYPE_STATE_COORDINATOR
            const { success, data } = await Client.api.get(`/admin/user/search?kind=${userKind}&qname=${value}`)
            if (success) {
                callback(data)
                // setShowList(data.length > 0)
            } else {
                callback([])
                // setShowList(false)
            }
            setSearching(false)
        }, 500)
    }

    const getUserData = async (id) => {
        setShowResult(false)
        const { success, data } = await Client.api.get('/admin/user/' + id)
        if (success) {
            setUser(data)
        } else {
            setUser(null)
        }
    }
    useEffect(() => {
        onClickItem(user)
    }, [user])
    return <div>

        <Components.input.Search
            placeholder="Search name or id"
            onValueChange={searchUser}
            show={showResult}
            render={(user) => {
                return <div
                    className="course-batch__search-result-item"
                    onClick={() => {
                        getUserData(user._id)
                    }}
                >
                    <div>{user.name}</div>
                    <div>{user.username}</div>
                </div>
            }}
        />


    </div>
}

export default SearchCoOridinator