const getFormattedDate = (n) => {
    const d = new Date(n)
    return `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`
}

const getNumberOfDeunalPlaces = (arrOfNumberString) => {
    let decimalPlaces = 0
    let splitted = []
    for(let i in arrOfNumberString){
        const numberString = arrOfNumberString[i]
        splitted = numberString.split(".")
        decimalPlaces = splitted.length === 2? (splitted[1].length > decimalPlaces? splitted[1].length : decimalPlaces ): 0
    }
    return decimalPlaces
}

const getFormattedDateYYYYMMDD = (n) => {
    const t = new Date(n)
    let  s =   `${t.getFullYear()}`
    const  m = t.getMonth()+1
    const d = t.getDate()
    s = (m<10)?`${s}-0${m}`:`${s}-${m}`
    s = (d<10)?`${s}-0${d}`:`${s}-${d}`
    return s
}

export const precisionUpto = (n, p) => {
    if(n === undefined) return 0
    if(typeof n === "string") n = Number(n)
    const a = Math.pow(10, p)
    const t1 = Math.round(n)
    const t2 = Math.round(n * a)
    let r = Math.abs(t2 % a);
    r = Number(`${t1}.${r}`)
    return r
}

export const numberWithCommas = (x) => {
    if (x === undefined) return "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const utility = {
    getFormattedDate,
    getFormattedDateYYYYMMDD,
    precisionUpto,
    numberWithCommas,
    getNumberOfDeunalPlaces
}

export default utility