
import React from 'react';
import { useEffect, useState } from 'react';
import Components from '../../../../components';
import Client from '../../../../services';
import SearchSchool from './SearchSchool';


const headerMap = {
    "index": {label: "Index",},
    "entity": {label: "Entity Id"},
    "symbol": {label: "Symbol"},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "options": {label: "Options"}
}


class SchoolList extends React.Component{
    state = {
        list: [],
        selectedSchool: null
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = async () => {
        const {eid} = this.props
        const {data} = await Client.api.get(`/admin/entity/state/${eid}/school`)
        this.setState({list: data})
    }

    handleAddSchool = async (e, uid) => {
        e.preventDefault()
        const {selectedSchool} = this.state
        
        const {eid} = this.props
        const payload = {
            eid: selectedSchool._id
        }
        this.setState({selectedSchool: null})
        const {success, message} = await Client.api.put(`/admin/entity/state/${eid}/school`, payload)
        if (success){
            await Client.alert.fire({
                title: "Success",
                body: message || "State co-ordinator added succesfully.",
                confirmButton: true,
            })
            this.loadData()
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "failed to add state co-orordinator.",
                confirmButton: true
            })
        }
        
        
    }

    handleChangeCoOrdinator = (entity, e) => {
        e.preventDefault()
        let coordinatorId = ""
        const C = () => {
            const [value, setValue] = useState("")
            useEffect(()=>{
                coordinatorId = value
            }, [value])
            return <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Old Coordinator</td>
                            <td>{entity._coordinator}</td>
                        </tr>
                        <tr>
                            <td>New Coordinator Id</td>
                            <td>
                                <Components.input.TextInput onChange={(e) => setValue(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        Client.alert.fire({
            title: "Change co-ordinator",
            body: <C />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const payload = {
                    schoolId: entity._id, 
                    coordinatorId: coordinatorId
                }
                const {success, message} = await Client.api.put('/admin/entity/change/school/coordinator', payload)
                if (success){
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Co-ordintor changed succesfully.",
                        confirmButton: true,
                    })
                    this.props.history.replace('/admin/entity/list')
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "Failed to change the co-ordinator.",
                        confirmButton: true
                    })
                }
            }
        })
    }

  


    renderList = () => {
        return this.state.list.map((v,index) => {
            // return {index, ...v, options: <button onClick={this.handleChangeCoOrdinator.bind(this, v)}>Change Co Ordinator</button> }
            return {index: index+1, ...v, }
        })
    }
    render(){
        const {selectedSchool} = this.state
        return <div className="new-coordinator">
            <h3>Schools</h3>
            <SearchSchool onClickItem={(selectedSchool)=>this.setState({selectedSchool})}/>
            {
                selectedSchool && 
                <>
                    <table>
                        <tr>
                            <td>Name:</td>
                            <td>{selectedSchool.name}</td>
                        </tr>
                        <tr>
                            <td>User Id:</td>
                            <td>{selectedSchool.entity}</td>
                        </tr>
                        <tr>
                            <td>Symbol:</td>
                            <td>{selectedSchool.symbol}</td>
                        </tr>
                        <tr>
                            <td>Address:</td>
                            <td>{selectedSchool.address}</td>
                            <td>
                                <Components.buttons.FlatButton onClick={this.handleAddSchool}>Add to School List</Components.buttons.FlatButton>
                            </td>
                        </tr>
                        
                    </table>
                </>
            }
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "entity", "symbol","name", "address", "options"]}
                data={this.renderList()}
            />
        </div>
    }
}

export default SchoolList