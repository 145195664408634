


function Dashboard(props){

    return <div className="App">
        {/* <div>
            <Components.minicharts.Line y={[123,543,63,54,23,65,23]} />
        </div>
        <Components.charts.Line showAxis={{x: false, y: false}}/> */}

        {/* <Components.data.Calendar /> */}

        <h2>Public Page</h2>

    </div>
}

export default Dashboard