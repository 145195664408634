import { useEffect, useState } from "react"

{/* <Components.forms.MultiStepForm 
                totalSteps={4}
                steps={[
                    {
                        label: "Confirm Basic Details",
                    },
                    {
                        label: "Authorize identity",
                    },
                    {
                        label: "Making the Payment",
                    },
                    {
                        label: "Payment Information",
                    },
                    
                ]}
            /> */}

const MultiStepForm = ({totalSteps, steps}) => {
    const [render, reRender] = useState(0)
    totalSteps = totalSteps ? totalSteps : 1
    steps = steps ? steps : []
    for(let i= 0; i<totalSteps; i++){
        if(!(i < steps.length)){
            steps.push({
                label: "Step " + (i+1),
                view: <div>Step is not implemented</div>
            })
        }
    }
    // useEffect(()=>{
    //     console.log("Effect", steps)
    //     reRender(render+1)
    // }, [])
    console.log(steps)

    return <div className="form multistep-form">
        <div className="multistep-form__steps">
            {
                steps.map(({label}) => {
                    return <div className="multistep-form__step-label">{label}</div>
                })
            }
        </div>
    </div>
}

export default MultiStepForm