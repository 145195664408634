const clientLog = {
    api: (tag, endPoint, data) =>{
       console.info(`[ ${tag} ] [ ${endPoint} ] =>`, data)
    },
    i: (tag, info) =>{
        console.info(`[ ${tag} ] =>`, info)
    },
    e: (tag, msg, err) =>{
        console.error(`[ ${tag} ] => ${msg} \n ${err}`)
    },
    d: (tag, data) =>{
        console.debug(`[ ${tag} ] => ${data}`)
    } 
}

export default clientLog

