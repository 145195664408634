import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Components from '../../../components'
import Client from '../../../services'
const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "_uid": {label: "Teacher Id"},
    "subject": {label: "Subject"},
    "topic": {label: "topic"},
    "startTime": {label: "Date & Time"},
    "duration": {label: "Duration (minutes)"},
}

const LectureList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        
        const {success, data} = await Client.api.get(`/management/course/schedule/lectures?uid=6153835f3cb11685b424b82e`)
        if(success){
            const newList = data.map((v,i) => {
                const {data:lecture, ...schedule} = v
                console.log(schedule)
                return {
                    ...schedule, ...lecture,
                    startTime: (new Date(schedule.scheduledAt)).toLocaleString(),
                    index: i, actions: <button onClick={(e)=>props.history.push(`/management/course-batch/${v._id}`)} >View</button>}
            })
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="student-list">    
        <h4>Lectures</h4>
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "_uid", "subject", "topic", "startTime", "duration"]}
            data={list}
        />
    </div>
}

export default LectureList