import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Components from '../../../components'
import Client from '../../../services'
const headerMap = {
    "kind": {label: "Type"},
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "_uid": {label: "Teacher Id"},
    "subject": {label: "Subject"},
    "topic": {label: "topic"},
    "scheduledAt": {label: "Date & Time"},
    "duration": {label: "Duration"},
    "startedAt": {label: "Started At"},
    "hasAttendanceTaken": {label: "Attendance Taken"},
}

const LectureList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get(`/teacher/schedule/lecture/list?uid=615df1b7e287a0c0098f5193`)
        if(success){
            const newList = data.map((v,i) => {
                const {data:lecture, ...schedule} = v
                return {
                    ...schedule, ...lecture,
                    scheduledAt: (new Date(schedule.scheduledAt)).toLocaleString(),
                    index: i, 
                    duration: `${schedule.duration} mins`,
                    startedAt: schedule.startTime ? (new Date(schedule.startTime)).toLocaleString() :
                        <button onClick={async(e)=>{
                            const payload = {
                                uid: "615df1b7e287a0c0098f5193",
                                scheduleId: schedule._id
                            }
                            const {data} = await  Client.api.put('/teacher/schedule/lecture/mark/in', payload)
                            setList(oldList => {
                                oldList[i].startTime = data
                                console.log(oldList)
                                return [...oldList]
                            })
                        }} >Mark In</button>,
                    hasAttendanceTaken: !schedule.startTime ? null :
                     <button onClick={(e)=>props.history.push(`/teacher/schedule/lecture/${schedule._id}`)}>View</button>
                }
            })
            console.log(newList)
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="student-list">    
        <h2>Lectures</h2>
        <Components.data.Table 
            rowHover="wheat"
            headerMap={headerMap}
            headers={["index", "_id", "_uid", "subject", "topic", "scheduledAt", "duration", "startedAt", "hasAttendanceTaken"]}
            data={list}
        />
    </div>
}

export default LectureList