const Table = ({ className, headerMap, headers, data, rowHover }) => {
    className = `table ${className ? className : ""}`
    return <div className={className}>
        <table>
            {
                headers && <thead>
                    <tr>
                        {
                            headers.map((key, i) => {
                                if (!headerMap) return <th key={key}>{key}</th>
                                return <th key={key}>{headerMap[key].label}</th>
                            })
                        }
                    </tr>
                </thead>
            }

            {
                data && <tbody>
                    {
                        data.map(v => {
                            return <tr key={v._id} className={rowHover? `table--hover--${rowHover}` : ""}>{
                                headers ?
                                    headers.map(key => {
                                        return <td key={key}>{v[key]}</td>
                                    })
                                    :
                                    Object.keys(v).map(key => {
                                        return <td key={key}>{v[key]}</td>
                                    })
                            }</tr>
                        })
                    }
                </tbody>
            }
        </table>
    </div>
    
}

export default Table