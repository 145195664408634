import StudentList from './StudentList'
import Dashboard from './Dashboard'
import Lecture from './Lecture'
import Payment from './Payment'
const exportObj = {
    Dashboard,
    Lecture,
    Payment,
    StudentList
}

export default exportObj