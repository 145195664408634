import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';
import Client from '../../../services'

const headerMap = {
    "index": {label: "Sl.No.",},
    "username": {label: "User id",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "contact": {label: "Phone no"},
    "email": {label: "Email"},
    "createdAt": {label: "Created At",}
}



const StudentList = ({...props}) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get('/admin/student/list')

        if(success){
            const newList = data.map((user, i) => {
                return {
                    ...user,
                    index: i+1,
                    name: <Link to={`/admin/student/${user._id}`}>{user.name}</Link>,
                }
            })
            setList(newList)
        }else{
            setList([])
        }
    },[])

    const headers = ["index", "username", "name", "address", "createdAt"]


    return <div className="student-list">     
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default StudentList