import React from 'react';
import Components from '../../../components';
import Client from '../../../services';

const entityTypes = {
    [Client.constants.entity.kind.ENTITY_STATE]: "STATE",
    [Client.constants.entity.kind.ENTITY_SCHOOL]: "SCHOOL",
}

class NewEntityForm extends React.Component{
    state = {
        kind: "",
        entity: "",
        name: "",
        address: "",
        symbol: "",
    }

    handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const {kind, name, entity, address, symbol} = this.state
        const urls = {
            [Client.constants.entity.kind.ENTITY_STATE]: '/admin/entity/state',
            [Client.constants.entity.kind.ENTITY_SCHOOL]: '/admin/entity/school',
        }
        const url = urls[kind]
        if(!kind){
            Client.alert.fire({
                icon: "Error",
                body: "Select the kind of entity."
            })
            return
        }
        
        const payload = {name, address, entity, symbol}
        
        const {success, message} = await Client.api.post(url, payload)

        if (success){
            await Client.alert.fire({
                title: "Success",
                body: message || "New co ordintor succesfully registered.",
                confirmButton: true,
            })
            this.props.history.replace('/admin/entity/list')
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "New co ordintor registration failed.",
                confirmButton: true
            })
        }
    }

    render(){
        const {name, address, entity, kind, symbol} = this.state
        return <div className="new-coordinator">
            <h2>Create new entity</h2>
            <Components.forms.SimpleForm>
                <table>
                    <tbody>
                        <tr>
                            <td>Kind of Entity</td>
                            <td>
                                <Components.input.Select.Field name="kind" value={kind} onChange={this.handleChange} >
                                    <Components.input.Select.Option value="">Select One</Components.input.Select.Option>
                                    {
                                        Object.keys(entityTypes).map(key => {
                                            return <Components.input.Select.Option key={key} value={key}>{entityTypes[key]}</Components.input.Select.Option>
                                        })
                                    }
                                </Components.input.Select.Field>
                            </td>
                        </tr>
                        <tr>
                            <td>Entity Id</td>
                            <td><Components.input.TextInput name="entity" placeholder="Enter unique id" value={entity} onChange={this.handleChange} /></td>
                        </tr>
                        <tr>
                            <td>Symbol</td>
                            <td><Components.input.TextInput name="symbol" placeholder="Symbol" value={symbol} onChange={this.handleChange} /></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td><Components.input.TextInput name="name" placeholder="Name" value={name} onChange={this.handleChange} /></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td><Components.input.TextArea name="address" placeholder="Address" value={address} onChange={this.handleChange} /></td>
                        </tr>
                    </tbody>
                </table>
                <Components.buttons.FlatButton onClick={this.handleSubmit}> Submit</Components.buttons.FlatButton>
            </Components.forms.SimpleForm>
        </div>
    }
}

export default NewEntityForm