
import React from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components';
import Client from '../../../services';


const headerMap = {
    "index": {label: "Index",},
    "_id": {label: "Id",},
    "name": {label: "Name",},
    "address": {label: "Address",}
}


class CoOrdinatorList extends React.Component{
    state = {
        list: []
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = async () => {
        const {data} = await Client.api.get('/admin/user/coordinator/list')
        this.setState({list: data})
    }



    renderList = () => {
        return this.state.list.map((v,index) => {
            return {index, ...v}
        })
    }
    render(){
        return <div className="new-coordinator">
            <ul>
                <li><Link to="/admin/coordinator/register">Register Co-ordinator</Link></li>
            </ul>
            
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "_id", "name", "address"]}
                data={this.renderList()}
            />
        </div>
    }
}

export default CoOrdinatorList