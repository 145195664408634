
import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Components from '../../../../components';
import Client from '../../../../services';
import SearchCoOridinator from './SearchCoOrdinator';


const headerMap = {
    "index": {label: "Index",},
    "username": {label: "User Id"},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "email": {label: "Email",},
    "contact": {label: "Phone No",},
    "options": {label: "Options"}
}


class CoOrdinatorList extends React.Component{
    state = {
        list: [],
        selectedNewCoOrdinator: null
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = async () => {
        const {eid} = this.props
        const {data} = await Client.api.get(`/admin/entity/state/${eid}/coordinator`)
        this.setState({list: data})
    }

    handleAddCoOrdinator = async (e, uid) => {
        e.preventDefault()
        const {selectedNewCoOrdinator} = this.state
        
        const {eid} = this.props
        const payload = {
            uid: selectedNewCoOrdinator._id
        }
        this.setState({selectedNewCoOrdinator: null})
        const {success, message} = await Client.api.put(`/admin/entity/state/${eid}/coordinator`, payload)
        if (success){
            await Client.alert.fire({
                title: "Success",
                body: message || "State co-ordinator added succesfully.",
                confirmButton: true,
            })
            this.loadData()
        }else{
            Client.alert.fire({
                title: "Failed",
                body: message || "failed to add state co-orordinator.",
                confirmButton: true
            })
        }
        
        
    }

    handleChangeCoOrdinator = (entity, e) => {
        e.preventDefault()
        let coordinatorId = ""
        const C = () => {
            const [value, setValue] = useState("")
            useEffect(()=>{
                coordinatorId = value
            }, [value])
            return <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Old Coordinator</td>
                            <td>{entity._coordinator}</td>
                        </tr>
                        <tr>
                            <td>New Coordinator Id</td>
                            <td>
                                <Components.input.TextInput onChange={(e) => setValue(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        Client.alert.fire({
            title: "Change co-ordinator",
            body: <C />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const payload = {
                    schoolId: entity._id, 
                    coordinatorId: coordinatorId
                }
                const {success, message} = await Client.api.put('/admin/entity/change/school/coordinator', payload)
                if (success){
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Co-ordintor changed succesfully.",
                        confirmButton: true,
                    })
                    this.props.history.replace('/admin/entity/list')
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "Failed to change the co-ordinator.",
                        confirmButton: true
                    })
                }
            }
        })
    }

  


    renderList = () => {
        return this.state.list.map((v,index) => {
            // return {index, ...v, options: <button onClick={this.handleChangeCoOrdinator.bind(this, v)}>Change Co Ordinator</button> }
            return {index: index+1, ...v, }
        })
    }
    render(){
        const {selectedNewCoOrdinator} = this.state
        return <div className="new-coordinator">
            <h3>Co-ordinators</h3>
            <SearchCoOridinator onClickItem={(selectedNewCoOrdinator)=>this.setState({selectedNewCoOrdinator})}/>
            {
                selectedNewCoOrdinator && 
                <>
                    <table>
                        <tr>
                            <td>Name:</td>
                            <td>{selectedNewCoOrdinator.name}</td>
                        </tr>
                        <tr>
                            <td>User Id:</td>
                            <td>{selectedNewCoOrdinator.username}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{selectedNewCoOrdinator.email}</td>
                        </tr>
                        <tr>
                            <td>Phone no:</td>
                            <td>{selectedNewCoOrdinator.contact}</td>
                            <td>
                                <Components.buttons.FlatButton onClick={this.handleAddCoOrdinator}>Add to Co-Ordinator List</Components.buttons.FlatButton>
                            </td>
                        </tr>
                        
                    </table>
                </>
            }
            <Components.data.Table
                headerMap={headerMap}
                headers={["index", "username", "name", "address", "email", "contact", "options"]}
                data={this.renderList()}
            />
        </div>
    }
}

export default CoOrdinatorList