const NotificationTile = ({from, to, kind, title, description, target, createdAt, ...props}) => {
    return <div className="notification-tile">
        <div className="notification-tile__from">
            From @{from} {" "}
            to { target === "INDIVISUAL" || target === "ALL" ? null : `the ${target} of`} {" "}
            @{ target !== "ALL"?  to : null }
        </div>

        <div className="u-flex--row u-flex--center-cross">
            <div className="notification-tile__title">{title}</div>
            <div className="notification-tile__kind">[ {kind} ]</div>
            <div className="u-flex--1"></div>
            <div className="notification-tile__kind">[ {target} ]</div>
        </div>
        <div className="notification-tile__description">{description}</div>

        <div className="u-flex--row">
            <div className="notification-tile__from">@{from}</div>
            <div className="u-flex--1"></div>
            <div className="notification-tile__datetime">{createdAt}</div>
        </div>
        
    </div>
}

export default NotificationTile