import buttons from './buttons'
import charts from './charts'
import data from './data'
import input from './input'
import forms from './forms'
import minicharts from './minicharts'
import nav from './nav'

const Components = {
    buttons,
    charts,
    data,
    forms,
    input,
    minicharts,
    nav
}

export default Components