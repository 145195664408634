import React from "react"
import { Doughnut, Line } from 'react-chartjs-2';

const LineChart = ({
    height,
    width,
    x=[0,1,2,3,4,5,6,7,8,9,10], 
    y=[{
        label: "Dataset",
        data:[1,2,3,4,5,6,7,8,9]
    }],
    showAxis = {x: true, y: true},
    showGrid={x:true, y:true},
    responsive=true,
    stacked=false,
    interaction= {
        mode: 'index',
        intersect: false,
    },
    legend= {
        position: 'none',
    },
    title = "",
    maintainAspectRatio= false,
    pointRadius=2
}) => {
    
    const data = {
        labels: x,
        datasets: y.map(v => {
            return {
                ...v,
                
            }
        })
    }
    const options = {
        responsive: responsive,
        stacked: stacked,
        interaction: interaction,
        maintainAspectRatio: maintainAspectRatio,
        elements: {
            point: {
                radius: pointRadius
            }
        },
        plugins: {
            // stacked: false,
            legend:legend,
            title: {
                display: !!title,
                text: title
            },
        },
        scales:{
            x:{
                display: showAxis.x,
                grid:{
                    drawOnChartArea: showGrid.x,
                }
                
            },
            y: {
                display: showAxis.y,
                grid:{
                    drawOnChartArea: showGrid.y,
                }
            },
            
        }
    }
    return <div style={{display: "inline-block"}}>
        <Line
            height={height}
            width={width}
            data={data}
            options={options}
            
        />
    </div>
}

export default LineChart