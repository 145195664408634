import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Components from '../../../components'
import Client from '../../../services';


const headerMap = {
    "index": {label: "Sl. No."},
    "_id": {label: "Id"},
    "title": {label: "Course Title"},
    "nLevel": {label: "No of Levels"},
    "duration": {label: "Level Duration"},
    "cost": {label: "Cost per level"},
    "actions": {label: "Actions"},
}

const CourseList = (props) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(async()=>{
        const {success, data} = await Client.api.get(`/admin/online-course/list`)
        if(success){
            const newList = data.map((v,i) => {
                return {...v, index: i, actions: <button onClick={(e)=>props.history.push(`/admin/online-course/${v._id}`)} >View</button>}
            })
            setList(newList)

        }else{
            setList( [] )
        }
    },[])




    return <div className="course-list">  
        <h2>Course List</h2>  
        <ul>
            <li><Link to="/admin/online-course/create">New Course</Link></li>
        </ul> 
        <Components.data.Table 
            headerMap={headerMap}
            headers={["index", "_id", "title", "nLevel", "duration", "cost", "actions"]}
            data={list}
        />
    </div>
}

export default CourseList