const TextInput = ({leading, trailing, header, footer, ...props}) => {

    return <div className="input input-text">
        <div className="input-text__header">{header}</div>
        <div className="u-flex--row">
            <div className="input-text__leading">{leading}</div>
            <input type="text" {...props} />
            <div className="input-text__trailing">{trailing}</div>
        </div>
        <div className="input-text__footer">{footer}</div>
    </div>
}

export default TextInput