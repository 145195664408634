const TextArea = ({ id, header, name, cols=5, rows, value, onChange, placeholder}) =>{
    const attr = {cols, name, value, onChange, placeholder}
    if(id) attr.id = id
    if(rows) attr.rows = rows

    return <div className="textarea">
        <div className="textarea__header">{header}</div>
        <textarea {...attr} ></textarea>
    </div>
}

export default TextArea;