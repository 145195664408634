import React from 'react'
import { Link } from 'react-router-dom'
import Components from '../../components'


class Dashboard extends React.Component{
    render(){
        return <div className="dashboard of-admin">
            <Components.data.Calendar/>

            {/* <Link to="/admin/student/list">Get Student List</Link>
            <Link to="/teacher/list">Get Teacher List</Link>
            <Link to="/course/list">Get Course List</Link> */}
        </div>
    }
}

export default Dashboard