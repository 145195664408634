import React, { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import Components from '../../../components'
import TeacherDetails from '../../../pageComponents/TeacherDetails'
import Client from '../../../services'
import AddStudent from './AddStudent'
import AddTeacher from './AddTeacher'
import LectureList from './LectureList'
import StudentList from './StudentList'
import TeacherList from './TeacherList'

class StudentBatchDetails extends React.Component{
    state = {
        batch: {},
        students: [],
        teachers: [],
        lectures: [],
    }
    async componentDidMount(){
        const {id} = this.props.match.params
        Client.api.get(`/management/course/batch/${id}`).then( ({success, data:batch, message}) => {
            if(success){
                this.setState({
                    batch
                })
            }else{
                Client.alert.fire({
                    icon: "failure",
                    title: "Loading failed",
                    body: message,
                    confirmButton: true,
                })
            }
        })
        Client.api.get(`/management/course/batch/${id}/students`).then( ({success, data:students}) => {
            if(success){
                this.setState({
                    students: this.parseStudentList(students)
                })
            }
        })
        Client.api.get(`/management/course/batch/${id}/teachers`).then( ({success, data:teachers}) => {
            if(success){
                this.setState({
                    teachers
                })
            }
        })
        Client.api.get(`/management/course/batch/${id}/lectures`).then( ({success, data }) => {
            if(success){
                const lectures = data.map( (v, i) => {
                    const {_id:{_uid: teacher, ...schedule}, ...lecture} = v
                    return {
                        index: i+1,
                        _id: schedule._id, 
                        scheduledAt: schedule.scheduledAt, 
                        duration: schedule.duration,
                        subject: lecture.subject,
                        topic: lecture.topic,
                        teacher: teacher.name
                    }
                })
                this.setState({
                    lectures
                })
            }
        })
    }

    // ============================================================================================================
    // Parsers
    // ============================================================================================================
    parseStudentList = (students) => {
        return students.map(s => {
            return {...s,
                actions: <Fragment>
                    <i className="fas fa-trash" onClick={this.handleRemoveStudent.bind(this,s._id)}></i>
                </Fragment>
            }
        })
    }
    // ============================================================================================================
    // Api Calls
    // ============================================================================================================
    removeStudent = async(studentId) => {
        const {id} = this.props.match.params
        const {success, data, message} = await Client.api.delete(`/management/course/batch/${id}/student/${studentId}`)
        
        Client.alert.fire({
            icon: success? "success" : "failure",
            body: message,
            confirmButton: true,
        })
        
    }

    
    // ============================================================================================================
    // Handlers
    // ============================================================================================================
    handleAddStudent = (e) => {
        e.preventDefault()
        let studentId = ""
        const handleClickStudent = (id) => studentId = id 
        Client.alert.fire({
            title: "Add Student",
            body: <AddStudent onClickStudent={handleClickStudent} />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const {batch} = this.state
                const payload = {
                    studentId: studentId
                }
                const {success, message} = await Client.api.put(`/management/course/batch/${batch._id}/student`, payload)
                if (success){
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Student added succesfully.",
                        confirmButton: true,
                    })
                    this.props.history.replace('/management/course-batch/list')
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "failed to add student.",
                        confirmButton: true
                    })
                }
            }
        })
    }

    handleAddTeacher = (e) => {
        e.preventDefault()
        let teacherId = ""
        const handleClickTeacher = (id) => teacherId = id 

        Client.alert.fire({
            title: "Add Teacher",
            body: <AddTeacher onClickTeacher={handleClickTeacher} />,
            confirmButton: true,
            cancelButton: true,
            confirmButtonText: "Submit",
            preConfirm: async () => {
                const {batch} = this.state
                const payload = {
                    teacherId: teacherId
                }
                const {success, message} = await Client.api.put(`/management/course/batch/${batch._id}/teacher`, payload)
                if (success){
                    await Client.alert.fire({
                        title: "Success",
                        body: message || "Teacher added succesfully.",
                        confirmButton: true,
                    })
                    this.props.history.replace('/management/course-batch/list')
                }else{
                    Client.alert.fire({
                        title: "Failed",
                        body: message || "Failed to add teacher.",
                        confirmButton: true
                    })
                }
            }
        })
    }

    handleRemoveStudent = (id, e) => {
        e.preventDefault()
        Client.alert.fire({
            icon: "warning",
            title: "Remove student",
            body: "Do you want to remove the student from the list? Please confirm your action.",
            confirmButton: true,
            denyButton: true,
            preConfirm: () => this.removeStudent(id)
        })
        
    }
    handleScheduleLecture = (e) => {
        e.preventDefault()

        this.props.history.push(`/management/schedule/lecture?bid=${this.state.batch._id}`)
    }

    render(){
        const {batch} = this.state
        return <div className="student-batch-details">
            <h2>Course Batch Details</h2>
            <div>Batch Title: {batch.title}</div>
            <div>Batch Description: {batch.description}</div>
            <div>No of Students: {batch.nStudents}</div>
            <div>No of Teachers: {batch.nTeachers}</div>


            <div>
                <h3>Schedules</h3>
                <Components.buttons.FlatButton onClick={this.handleScheduleLecture}>Schedule Lecture</Components.buttons.FlatButton>
                <LectureList list={this.state.lectures} />
            </div>

            <div>
                <h3>Students</h3>
                <Components.buttons.FlatButton onClick={this.handleAddStudent}>Add student</Components.buttons.FlatButton>
                <StudentList list={this.state.students} />
            </div>
            <div>
                <h3>Teachers</h3>
                <Components.buttons.FlatButton onClick={this.handleAddTeacher}>Add teacher</Components.buttons.FlatButton>
                <TeacherList list={this.state.teachers} />
            </div>

        </div>
    }
}

export default StudentBatchDetails