import React, { useEffect, useState } from 'react';
import Components from '../../components';

import Student from '../../models/student'

const headerMap = {
    "id": {label: "Sl.No.",},
    "name": {label: "Name",},
    "address": {label: "Address",},
    "joined": {label: "Created At",},
    "course": {label: "Current Course",},
}



const StudentList = ({...props}) => {
    
    var [list, setList] = useState([])
    // Component Did mount
    useEffect(()=>{
        const template = {id: 0, name: "Student Name", joined: (new Date()).toDateString(), course: "Abc Course",}
        const newList = []
        for(let i=0; i < 100; i++){
            const obj = {...template, id: i+1}
            newList.push(obj)
        }
        setList(newList)
    },[])

    const headers = ["id", "name", "address", "joined", "course"]


    return <div className="student-list">     
        <Components.data.Table headerMap={headerMap} headers={headers} data={list}/>
    </div>
}

export default StudentList