import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Components from '../../../components'
import Client from '../../../services'

const headerMap = {
    "kind": {label: "Type"},
    "index": {label: "Sl. No."},
    "_uid": {label: "Id"},
    "name": {label: "Name"},
    "status": {label: "Status"},
    "actions": {label: "Actions"},
}


class AttendeeList extends React.Component{
    state = {
        list: []
    }

    async componentDidMount(){
        const {success, data} = await Client.api.get(`/teacher/schedule/lecture/attendance?scheduleId=${this.props.scheduleId}`)
        if(success){
            const list = data.attendees.map(attendee => {
                return {...attendee}
            })
            this.setState({list})
            console.log(list)
        }
    }

    
    // shouldComponentUpdate(){
    //     return true;
    // }

    handleToggleMark = (id, index, e) => {
        const cb = (v) => {
            this.state.list[index].checkIn = v
            this.forceUpdate()
        }
        this.props && this.props.onMarkPresent(id, e, cb)
    }


    renderList = () => {
        return this.state.list.map((attendee, i) => {
            const isPresent = attendee.checkIn || attendee.checkOut
            return {
                id: attendee._id,
                index: i+1, 
                ...attendee,
                status: isPresent ? "Attended" : "-",
                actions: <Components.input.MultiChoice checked={isPresent} onChange={this.handleToggleMark.bind(this, attendee._id, i)}/>
            }
        })
    }

    render(){
        return <div className="student-list">    
            <h3>Attendance</h3>
            <Components.data.Table 
                rowHover="wheat"
                headerMap={headerMap}
                headers={["index", "_uid", "name", "status", "actions"]}
                data={this.renderList()}
            />
        </div>
    }
} 
// = ({ scheduleId, onMarkPresent, ...props}) => {
    
//     var [list, setList] = useState([])
//     // Component Did mount
//     useEffect(async()=>{
//         const {success, data} = await Client.api.get(`/teacher/schedule/lecture/attendance?scheduleId=${scheduleId}&uid=615df1b7e287a0c0098f5193`)
        
//         if(success){
//             const newList = Object.keys(data.attendees).map((key,i) => {
//                 const attendee = data.attendees[key]
//                 const isPresent = attendee.checkIn || attendee.checkOut
                
//                 return {
//                     id: key,
//                     index: i+1, 
//                     ...attendee,
//                     status: isPresent ? "Attended" : "-",
//                     actions: <Components.input.MultiChoice checked={isPresent} onChange={
//                         (e)=>{
//                             const cb = (v) => setList(oldList => {
//                                 oldList[i].startTime
//                             })
//                             onMarkPresent(key, e)
//                         } 
//                     }/>
//                 }
//             })
//             console.log(newList)
//             setList(newList)

//         }else{
//             setList( [] )
//         }
//     },[])
//     useEffect(()=>{}, [list])




    
// }

export default AttendeeList