import { useEffect } from "react"
import { useState } from "react"
import Components from "../../../../components"
import Client from "../../../../services"
import { ClientSession } from "../../../../services/session"

const SearchSchool = ({ onClickItem }) => {
    const [searching, setSearching] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [value, setValue] = useState("")
    const [user, setUser] = useState(null)
    let timeoutId = -1
    const searchUser = async (e, callback) => {
        const { value } = e.target
        if (showResult === false) setShowResult(true)
        clearTimeout(timeoutId)
        // setSid(value)
        if (value.length < 3) {
            callback([])
            // setShowList(false)
        }
        timeoutId = setTimeout(async () => {
            const entityKind = Client.constants.entity.kind.ENTITY_SCHOOL
            const { success, data } = await Client.api.get(`/admin/entity/search?kind=${entityKind}&q=${value}`)
            if (success) {
                callback(data)
                // setShowList(data.length > 0)
            } else {
                callback([])
                // setShowList(false)
            }
            setSearching(false)
        }, 500)
    }

    const getUserData = async (id) => {
        setShowResult(false)
        const { success, data } = await Client.api.get('/admin/entity/school/' + id)
        if (success) {
            setUser(data)
        } else {
            setUser(null)
        }
    }
    useEffect(() => {
        onClickItem(user)
    }, [user])
    return <div>

        <Components.input.Search
            placeholder="Search name or id"
            onValueChange={searchUser}
            show={showResult}
            render={(school) => {
                return <div
                    className="course-batch__search-result-item"
                    onClick={() => {
                        getUserData(school._id)
                    }}
                >
                    <div>{school.name}</div>
                    <div>{school.username}</div>
                </div>
            }}
        />


    </div>
}

export default SearchSchool