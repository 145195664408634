import React from "react";
import Components from "../../../components";
import Client from "../../../services";

class SendNotification extends React.Component{

    state = {
        type: Client.constants.notfication.types.ALL,
        to: "",
        title: "",
        description: "",

        showReview: false,
    }

    sendNotification = async() => {
        const {type, to, title, description} = this.state
        const payload = {title, description}
        if(type === Client.constants.notfication.types.INDIVISUAL) payload.toUsername = to.trim()
        else if (type !== Client.constants.notfication.types.ALL) payload.entity = to.trim()
        
        let path
        if(type === Client.constants.notfication.types.INDIVISUAL) path=""
        else path = type.toLowerCase()
        
        const {success, data, message} = await Client.api.post(`/common/notification/${path}`, payload)
        if(success){
            Client.alert.fire({
                icon: "success",
                title: "Notification sent",
                body: message || "Notification send successully.",
                confirmButton: true,
            })
            this.props.onSuccess && this.props.onSuccess(data)
        }else{
            Client.alert.fire({
                icon: "failed",
                title: "Failed",
                body: message || "Failed to create tje motification.",
                confirmButton: true,
            })
        }
    }


    handleChange = (e) => {
        e.preventDefault()
        const {type, name} = e.target
        let {value} = e.target
        
        this.setState({
            [name]: value
        })
    }

    handleEdit = (e) => {
        this.setState({showReview: false})
    }

    handleSend = (e) => {
        this.setState({showReview: true})
    }

    handleConfirmSend = () => {
        this.sendNotification()
    }

    // ===============================================================================================================
    // Renderers
    // ===============================================================================================================
    renderReciverInput = () => {
        if(this.state.type === Client.constants.notfication.types.ALL) return null;
        
        if(this.state.type === Client.constants.notfication.types.INDIVISUAL) return <div className="notification-send__to">
            <Components.input.TextInput leading="Receiver Id" name="to" value={this.state.to}  onChange={this.handleChange}  />        
        </div>;
        
        return <div className="notification-send__to">
            <Components.input.TextInput leading="School Id" name="to" value={this.state.to} onChange={this.handleChange} />        
        </div>;
        
    }

    renderForm = () => {
        const types = Client.constants.notfication.types;

        return <>
            <div className="notification-send__receiver u-flex--row">
                <Components.input.Select.Field   className="notification-send__type" leading="Type" name="type" value={this.state.type} onChange={this.handleChange}>
                    {
                        Object.keys(types).map( key => 
                            <Components.input.Select.Option key={key} value={key} >{types[key]}</Components.input.Select.Option>
                        )
                    }
                </Components.input.Select.Field>
                {this.renderReciverInput()}
            </div>
            <Components.input.TextInput leading="Title" name="title" value={this.state.title} onChange={this.handleChange} />
            <Components.input.TextArea header="Description" name="description" cols={5} value={this.state.description} onChange={this.handleChange} />
            <div className="u-flex--row">
                <div className="u-flex--1"></div>
                <Components.buttons.FlatButton onClick={Client.alert.close}>Cancel</Components.buttons.FlatButton>
                <Components.buttons.FlatButton onClick={this.handleSend}>Send</Components.buttons.FlatButton>
            </div>
        </>
    }

    renderReview = () => {
        const types = Client.constants.notfication.types;

        return <>
            <div className="notification-send__receiver u-flex--row">
                <Components.input.Select.Field   className="notification-send__type" leading="Type" name="type" value={this.state.type} onChange={this.handleChange}>
                    {
                        Object.keys(types).map( key => 
                            <Components.input.Select.Option key={key} value={key} >{types[key]}</Components.input.Select.Option>
                        )
                    }
                </Components.input.Select.Field>
                {this.renderReciverInput()}
            </div>
            <Components.input.TextInput leading="Title" name="title" value={this.state.title} onChange={this.handleChange} />
            <Components.input.TextArea header="Description" name="description" cols={5} value={this.state.description} onChange={this.handleChange} />
            <div className="u-flex--row">
                <div className="u-flex--1"></div>
                <Components.buttons.FlatButton onClick={this.handleEdit}>Edit</Components.buttons.FlatButton>
                <Components.buttons.FlatButton onClick={this.handleConfirmSend}>Send</Components.buttons.FlatButton>
            </div>
        </>
    }

    render(){

        return <div className="notification-send">
            {this.state.showReview ? this.renderReview() : this.renderForm()}
        </div>
    }
}

export default SendNotification