import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SideNav from './pageComponents/SideNav';
import Containers from './containers'
import Client from './services';

const Dashboard = (props) => {
  switch(Client.session.userType){
    case Client.session.typeAccountant: return <Containers.accountant.Dashboard {...props} />
    case Client.session.typeAdmin: return <Containers.admin.Dashboard {...props} />
    case Client.session.typeManagement: return <Containers.management.Dashboard {...props} />
    case Client.session.typeStudent: return <Containers.student.Dashboard {...props} />
    case Client.session.typeTeacher: return <Containers.teacher.Dashboard {...props} />
  }
  return <h1>Invalid Dashboard</h1>
}

function App() {
  
  return (
    <div className="App">
      <SideNav/>
      <div className="container">
        <Switch>

          <Route exact path="/common/notification" component={Containers.common.Notification.List}/>
          <Route exact path="/common/notification/sent" component={Containers.common.Notification.Sent}/>

          <Route exact path="/accountant" component={Containers.accountant.FeesCollection} />
          <Route exact path="/accountant/pay/:type/fee/:id" component={Containers.accountant.MakePayment} />

          <Route exact path="/dashboard" component={Dashboard} />
          

          <Route exact path="/admin/accountant/payment/report" component={Containers.admin.Accountant.PaymentReport} />
          
          <Route exact path="/admin/coordinator/register" component={Containers.admin.CoOrdinator.Register} />
          <Route exact path="/admin/coordinator/list" component={Containers.admin.CoOrdinator.List} />
          <Route exact path="/admin/coordinator/details/:id" component={() => <div>Co ordinator details page</div>} />

          <Route exact path="/admin/course/create" component={Containers.admin.Course.Create} />
          <Route exact path="/admin/course/list" component={Containers.admin.Course.List} />
          <Route exact path="/admin/course/:id" component={Containers.admin.Course.Details} />

          
          <Route exact path="/admin/online-course/create" component={Containers.admin.OnlineCourse.Create} />
          <Route exact path="/admin/online-course/list" component={Containers.admin.OnlineCourse.List} />
          {/* <Route exact path="/admin/online-course/:id" component={Containers.admin.OnlineCourse.Details} /> */}
          <Route exact path="/admin/online-course/:id" component={Containers.admin.OnlineCourse.Details} />

          <Route exact path="/admin/entity/list" component={Containers.admin.Entity.List} />
          <Route exact path="/admin/entity/school/register" component={Containers.admin.Entity.RegisterSchool} />
          <Route exact path="/admin/entity/school/:id" component={Containers.admin.Entity.SchoolView} />
          <Route exact path="/admin/entity/state/:id" component={Containers.admin.Entity.StateView} />
          <Route exact path="/admin/entity/new" component={Containers.admin.Entity.New} />
          <Route exact path="/admin/student/list" component={Containers.admin.Student.List} />
          <Route exact path="/admin/student/:id" component={Containers.admin.Student.View} />
          <Route exact path="/admin/user/list" component={Containers.admin.Users.List} />
          <Route exact path="/admin/user/new" component={Containers.admin.Users.New} />
          

          <Route exact path="/course" component={Containers.course.CourseList} />
          <Route exact path="/course/list" component={Containers.course.CourseList}/>

  {/* ================================================================================================================== */}
  {/* ================================================== CoOrdinator ===================================================== */}
  {/* ================================================================================================================== */}
          <Route exact path="/coordinator/user/list" component={Containers.coordinator.Users.List} />
          <Route exact path="/coordinator/user/new" component={Containers.coordinator.Users.New} />

  {/* ================================================================================================================== */}
  {/* ================================================== CoOrdinator ===================================================== */}
  {/* ================================================================================================================== */}
          <Route exact path="/state-coordinator/entity/list" component={Containers.stateCoordinator.Entity.List} />
          <Route exact path="/state-coordinator/entity/school/:id" component={Containers.stateCoordinator.Entity.SchoolView} />
          <Route exact path="/state-coordinator/user/list" component={Containers.stateCoordinator.User.List} />


  {/* ================================================================================================================== */}
  {/* ================================================== Management ===================================================== */}
  {/* ================================================================================================================== */}
          
          <Route exact path="/management/accountant/register" component={Containers.management.Accountant.Registration} />
          <Route exact path="/management/accountant/list" component={Containers.management.Accountant.List} />


          
          <Route exact path="/management/course/list" component={Containers.management.Course.List} />
          <Route exact path="/management/course/:id" component={Containers.management.Course.Details} />

          <Route exact path="/management/course-batch/create" component={Containers.management.CourseBatch.Create} />
          <Route exact path="/management/course-batch/list" component={Containers.management.CourseBatch.List} />
          <Route exact path="/management/course-batch/:id" component={Containers.management.CourseBatch.Details} />
          
          <Route exact path="/management/schedule/lecture" component={Containers.management.Schedule.Lecture} />
          <Route exact path="/management/schedule/lecture/list" component={Containers.management.Schedule.LectureList} />
          
          <Route exact path="/management/student/register" component={Containers.management.Student.Registration} />
          <Route exact path="/management/student/list" component={Containers.management.Student.List} />
          <Route exact path="/management/student/enroll" component={Containers.management.Student.Enroll} />
          
          <Route exact path="/management/teacher/register" component={Containers.management.Teacher.Registration} />
          <Route exact path="/management/teacher/list" component={Containers.management.Teacher.List} />
          <Route exact path="/management/teacher/:id" component={Containers.management.Teacher.Details} />
  {/* ================================================================================================================== */}
  {/* ===================================================== Student ==================================================== */}
  {/* ================================================================================================================== */}
          <Route exact path="/student/lecture/list" component={Containers.student.Lecture.List} />
          <Route exact path="/student/list" component={Containers.student.StudentList} />
          <Route exact path="/student/payment/list" component={Containers.student.Payment.List} />

  {/* ================================================================================================================== */}
  {/* ===================================================== Teacher ==================================================== */}
  {/* ================================================================================================================== */}
          <Route exact path="/teacher/course-batch/list" component={Containers.teacher.CourseBatch.List} />
          <Route exact path="/teacher/course-batch/:id" component={Containers.teacher.CourseBatch.Details} />
          <Route exact path="/teacher/schedule/list" component={Containers.teacher.Schedule.List} />
          <Route exact path="/teacher/schedule/lecture/:id" component={Containers.teacher.Schedule.LectureDetails} />
          
        </Switch>
      </div>
    </div>
  );
}

export default App;
